import { takeLatest, put } from "redux-saga/effects";
import {
    CITY_DROPDOWN,
    CITY_DROPDOWN_SUCCESS,
    CITY_DROPDOWN_ERROR
} from "./constant";
import url from "services/urls.json";
import { postMethod } from "services/apiServices";
import { ClearTokenRedirectLogin } from "utils/general";


function* getCityDropdown(action) {

    try {
        const response = yield postMethod(url.getDropdown, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: CITY_DROPDOWN_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: CITY_DROPDOWN_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        }

    } catch (error) {
        yield put({ type: CITY_DROPDOWN_ERROR, payload: error });
    }
}

function* cityDropDownSaga() {
    yield takeLatest(CITY_DROPDOWN, getCityDropdown);
}

export default cityDropDownSaga;