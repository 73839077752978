import { ACTIVE_INDEX } from "./constant";

const initialDetails = {
    activeIndex: 1
};

const basicDetailsReducer = (state = initialDetails, action) => {
    switch (action.type) {
        case ACTIVE_INDEX:
            return { ...state, activeIndex: action.data };
        default:
            return state;
    }
};

export default basicDetailsReducer;
