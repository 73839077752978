import React, { useEffect, useState, useRef, useContext } from 'react';
import { Row, Col, Stack, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import SelectInput from 'components/common/Input/SelectInput';
import 'assets/styles/common.scss';
import 'components/feature/Teacher/TeacherDetails/TeacherDetails.scss';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import InputAdornment from '@mui/material/InputAdornment';
import Search from "assets/images/Registration/search.png";
import CheckValidation from 'components/feature/Teacher/TeacherDetails/validation';
import { TEACHING_MATERIAL_DROPDOWN } from 'store/Auth/Registration/TeachingMaterial/constant';
import { teachingMaterialDropDownCallAPI } from 'store/Auth/Registration/TeachingMaterial/action';
import { useDispatch, useSelector } from 'react-redux';
import { gradeLevelDropDownCallAPI } from 'store/Auth/Registration/GreadeLevel/action';
import { GRADE_LEVEL_DROPDOWN } from 'store/Auth/Registration/GreadeLevel/constant';
import { languageDropDownCallAPI } from 'store/Auth/Registration/Language/action';
import { LANGUAGE_DROPDOWN } from 'store/Auth/Registration/Language/constant';
import { subjectDropDownCallAPI } from 'store/Auth/Registration/Subject/action';
import { SUBJECT_DROPDOWN } from 'store/Auth/Registration/Subject/constant';
import { authRegisterCallAPI } from 'store/Auth/Registration/Auth/action';
import { AUTH_REGISTER } from 'store/Auth/Registration/Auth/constant';
import moment from "moment";
import ToastNotification from 'components/common/Toast';
import { sentEmail } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from "react-intl";

const TeacherDetails = () => {

    const navigate = useNavigate();
    const intl = useIntl();
    const { locale } = useContext(LocaleContext);
    const dispatch = useDispatch();
    const listRef = useRef(null);
    const [conf, setConf] = ToastNotification();
    const currentDate = new Date();

    const [selectMaterial, setSelectMaterial] = useState("IG");
    const [materialId, setMaterialId] = useState(19);
    const [isError, setIsError] = useState(false);
    const [isListVisible, setIsListVisible] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const [formValue, setFormValue] = useState({
        gradeLevel: "",
        language: "",
        experience: "",
        subject: ""
    });

    const [formId, setFormId] = useState({
        gradeLevel: "",
        language: "",
        subject: ""
    });

    const TeachingMaterialList = useSelector(state => state?.teachingMaterialDropDownReducer?.payload?.data);
    const GradeLevelList = useSelector(state => state?.gradeLevelDropDownReducer?.payload?.data);
    const SubjectList = useSelector(state => state?.subjectDropDownReducer?.payload?.data?.data);
    const LanguageList = useSelector(state => state?.languageDropDownReducer?.payload?.data);

    const personalDetails = useSelector(state => state?.RegistrationDetailsReducer?.personalDetailsData?.personalDetailsData);
    const passwords = useSelector(state => state?.RegistrationDetailsReducer?.passwordDetailsData?.passwordDetailsData);
    const professionalDetails = useSelector(state => state?.RegistrationDetailsReducer?.ProfessionalDetailsData?.ProfessionalDetailsData);

    const Roles = useSelector(state => state?.RegistrationDetailsReducer?.userRoles);


    useEffect(() => {
        getTeachingMaterialListApi();
        getGradeLevelListApi();
        getSubjectListApi();
        getLanguageListApi();
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (listRef.current && !listRef.current.contains(event.target)) {
            setIsListVisible(false);
        }
    };

    const getTeachingMaterialListApi = () => {
        let body = {
            "slug": "teaching-material"
        };
        dispatch(teachingMaterialDropDownCallAPI(TEACHING_MATERIAL_DROPDOWN, body, { navigate: navigate }));
    };

    const getGradeLevelListApi = () => {
        let body = {
            "slug": "gradlevel"
        };
        dispatch(gradeLevelDropDownCallAPI(GRADE_LEVEL_DROPDOWN, body, { navigate: navigate }));
    };

    const getSubjectListApi = () => {
        let body = {
            "slug": "subject"
        };
        dispatch(subjectDropDownCallAPI(SUBJECT_DROPDOWN, body, { navigate: navigate }));
    };

    const getLanguageListApi = () => {
        dispatch(languageDropDownCallAPI(LANGUAGE_DROPDOWN, {}, { navigate: navigate }));
    };


    const handleSubmit = () => {
        if (Roles !== "teacher") {
            delete formValue?.experience;
        }
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            let body = {
                "name": personalDetails?.fullName,
                "email": personalDetails?.email,
                "password": passwords?.password,
                "password_confirmation": passwords?.confirmPassword,
                "is_active": 0,
                "date_joined": moment(currentDate)?.format("DD-MM-YYYY"),
                "phone": personalDetails?.phoneNumber,
                "country_id": personalDetails?.country,
                "state_id": personalDetails?.state,
                "city_id": personalDetails?.city,
                "role_id": Roles === "teacher" ? 2 : 3,
                "highest_qualification": [
                    {
                        "qualificaiton_id": professionalDetails?.qualification,
                    }
                ],
                "subjects": [
                    {
                        "subject_id": formId?.subject,
                        "grade_id": formId?.gradeLevel,
                        "teaching_material_id": materialId,
                        "language_id": formId?.language,
                        "experience": formValue?.experience,
                        "status": 69
                    }
                ]
            }
            if (Roles !== "teacher") {
                delete body.country_id;
                delete body.state_id;
                delete body.city_id;
                delete body.subjects[0].experience;
                delete body.highest_qualification;
            }
            dispatch(authRegisterCallAPI(AUTH_REGISTER, body, { run: true, handleFunction: (res) => handleredirect(res), handleErrorFunction: (res) => handlereErrorCall(res), navigate: navigate, error: true }));
        }
    };


    const handleredirect = (res) => {
        if (res?.data?.status_code === 200) {
            navigate(sentEmail(locale));
        } else {
            setConf({ msg: intl.formatMessage({ id: "Useralreadyexists" }), variant: 'error' });
        }
    };

    const handlereErrorCall = (res) => {
        setConf({ msg: res?.data?.message, variant: 'error' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e?.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value?.name
        }));
        setFormId((prev) => ({
            ...prev,
            [name]: value?.id
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value
        }))
        if (name === "subject") {
            const filteredOptions = SubjectList && SubjectList?.length > 0 && SubjectList?.filter(option =>
                option?.name?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredOptions(filteredOptions);
        }
    };

    const handleItemClick = (item) => {
        setFormValue((prev) => ({
            ...prev,
            ['subject']: item?.name
        }));
        setFormId((prev) => ({
            ...prev,
            ['subject']: item?.id
        }));
        setIsListVisible(false);
    };

    const handleSelect = (data) => {
        setSelectMaterial(data?.name);
        setMaterialId(data?.id);

    };

    return (
        <Container className='container'>

            <Row className='pt-20  md:pt-120 mb-5'>
                <Row className='mb-5'>
                    <Col>
                        <h2 className='heading'><FormattedMessage id="register.WhatWillYouTeach" /></h2>
                    </Col>
                </Row>
                <Col xs={12} md={12}>
                    <p className='mb-2 teacher_label'><FormattedMessage id="register.TeachingMaterial" /></p>
                    <div className="flex flex-row items-center flex-wrap gap-3 teachingMaterial">
                        {TeachingMaterialList && TeachingMaterialList?.data?.length > 0 && TeachingMaterialList.data.map((k, i) => (
                            <p className={selectMaterial === k?.name ? 'selectTeachingMaterial' : 'unSelectTeachingMaterial'} onClick={() => handleSelect(k)}>{k?.name}</p>
                        ))}
                    </div>
                </Col>

                <Col xs={12} md={12} className='mt-2'>
                    <LabelWithInput
                        ref={listRef}
                        label={<FormattedMessage id="register.SelectASubject" />}
                        placeholder={intl.formatMessage({ id: "register.SelectASubject" })}
                        name="subject"
                        type="text"
                        value={formValue?.subject}
                        onChange={(e) => handleChange(e)}
                        onClick={() => setIsListVisible(true)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={Search} alt={"icon"} />
                                </InputAdornment>
                            )
                        }}
                        errorText={isError && isError.subject.error && isError.subject.errorMessage}
                    />
                    {isListVisible && (
                        <ul ref={listRef} style={{ width: "95%" }} className="custom-list">
                            {filteredOptions?.length > 0 && filteredOptions?.map((k) => (
                                <li className="list-item" onClick={() => handleItemClick(k)}>{k?.name}</li>
                            ))}
                        </ul>
                    )}

                </Col>

                <Col xs={12} md={12} className='mt-2'>
                    <SelectInput
                        label={<FormattedMessage id="register.GradeLevel" />}
                        placeholder={intl.formatMessage({ id: "register.GradeLevel" })}
                        name={"gradeLevel"}
                        value={formValue?.gradeLevel}
                        data={GradeLevelList?.data}
                        onChange={(e) => handleInputChange(e)}
                        errorText={isError && isError.gradeLevel.error && isError.gradeLevel.errorMessage}
                    />
                </Col>

                <Col xs={12} md={Roles === "teacher" ? 6 : 12} className='mt-2'>
                    <SelectInput
                        label={<FormattedMessage id="register.Language" />}
                        placeholder={intl.formatMessage({ id: "register.Language" })}
                        name={"language"}
                        value={formValue?.language}
                        data={LanguageList?.data}
                        onChange={(e) => handleInputChange(e)}
                        errorText={isError && isError.language.error && isError.language.errorMessage}
                    />
                </Col>

                {Roles === "teacher" &&
                    <Col xs={12} md={6} className='mt-2'>
                        <LabelWithInput
                            label={<FormattedMessage id="register.TeachingExperience" />}
                            placeholder={intl.formatMessage({ id: "register.TeachingExperience" })}
                            name="experience"
                            type="number"
                            value={formValue?.experience}
                            onChange={(e) => handleChange(e)}
                            errorText={isError && isError.experience.error && isError.experience.errorMessage}
                        />
                    </Col>
                }
            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'><FormattedMessage id="register.Continue" /></Button>
            </Row>

        </Container>
    );
};

export default TeacherDetails;
