export const getLandingURL = (locale) => `/${locale}`;
export const getTeacherDetails = (locale) => `/${locale}/teacher_details`;
export const pageNotFound = (locale) => `/${locale}/404`;
export const personalDetails = (locale) => `/${locale}/personal_details`;
export const setPassword = (locale) => `/${locale}/set_password`;
export const professionalDetails = (locale) => `/${locale}/professional_details`;

// export const teacherDetails = (locale) => `/${locale}/teacher_details`;
export const sentEmail = (locale) => `/${locale}/sent_email`;
export const profileSetup = (locale) => `/${locale}/profile_setup`;
export const successful = (locale) => `/${locale}/successful`;
export const login = (locale) => `/${locale}/login`;
export const adminLogin = (locale) => `/${locale}/admin/login`;
export const register = (locale) => `/${locale}/register`;
export const reset = (locale) => `/${locale}/reset`;
export const resetEmail = (locale) => `/${locale}/reset_email`;
export const resetPassword = (locale) => `/${locale}/reset_password`;
export const teacherDashboard = (locale) => `/${locale}/teacher_dashboard`;
export const questions = (locale) => `/${locale}/questions`;
export const profile = (locale) => `/${locale}/profile`;
export const OtpVerification = (locale) => `/${locale}/otp_verification`;
// export const InterViewRoundURL = (locale) => `/${locale}/interview_round`;
// export const InterViewRound = (locale) => `/${InterViewRoundURL(locale)}/:id`;

export const InterViewRound = (locale) => `/${locale}/interview_round`;


//////////////////////////////Student Urls ///////////////////////////////

export const studentDashboard = (locale) => `/${locale}/student_dashboard`;


//////////////////////////////Admin Urls ///////////////////////////////

export const adminDashboard = (locale) => `/${locale}/admin_dashboard`;
export const parentStudentUserManagement = (locale) => `/${locale}/parent/student`;
export const internalTeacherUserManagement = (locale) => `/${locale}/internal-teacher`;
export const teacherUserManagement = (locale) => `/${locale}/teacher`;