import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import Menu from "assets/images/TeacherDashboard/menu.svg";
import AdminProfileImage from "assets/images/Sidebar/adminProfileImage.svg";
import AccountIcon from "assets/images/Sidebar/account.svg";
import AssesmentIcon from "assets/images/Sidebar/assesment.svg";
import SubscriptionIcon from "assets/images/Sidebar/scan.svg";
import HelpIcon from "assets/images/Sidebar/help.svg";
import AccountIconColor from "assets/images/Sidebar/accountColor.svg";
import SubscriptionIconColor from "assets/images/Sidebar/assesmentColor.svg";
import AssesmentIconColor from "assets/images/Sidebar/scanColor.svg";
import HelpIconColor from "assets/images/Sidebar/helpColor.svg";
import LogOutIcon from "assets/images/Sidebar/logout.svg";
import DashboardIcon from "assets/images/Sidebar/dashboard.svg";
import DashboardIconColor from "assets/images/Sidebar/dashboardColor.svg";
import userManagementIcon from "assets/images/Sidebar/userManagement.svg";
import userManagementIconColor from "assets/images/Sidebar/userManagementColor.svg";
import CategoryIcon from "assets/images/Sidebar/category.svg";
import CategoryIconColor from "assets/images/Sidebar/categoryColor.svg";
import NotificationIcon from "assets/images/Sidebar/notification.svg";
import NotificationIconColor from "assets/images/Sidebar/notificationColor.svg";
import ManagerPlanIcon from "assets/images/Sidebar/managerPlan.svg";
import ManagerPlanIconColor from "assets/images/Sidebar/managerPlanColor.svg";
import "components/feature/Teacher/Sidebar/Sidebar.scss";
import "assets/styles/common.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/common/Toast';
import { LocaleContext } from 'routes/Router';
import { adminDashboard, internalTeacherUserManagement, login, parentStudentUserManagement, profile, questions, teacherDashboard, teacherUserManagement } from 'routes/AppUrls';
import { AUTH_LOGOUT } from 'store/Auth/Logout/constant';
import { authLogoutCallAPI } from 'store/Auth/Logout/action';
import { FormattedMessage, useIntl } from "react-intl";
import { basicDetailsCallApi } from 'store/BasicDetails/basicDetails/action';
import { ACTIVE_INDEX } from 'store/BasicDetails/basicDetails/constant';
import { getUserRole } from 'utils/general';
import { getProfileCallAPI } from 'store/Dashboard/Profile/action';
import { GET_PROFILE } from 'store/Dashboard/Profile/constant';


const Sidebar = (props) => {
  const { window } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { locale } = useContext(LocaleContext);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [conf, setConf] = ToastNotification();
  const userRole = getUserRole(); // Get the user's role

  const aIndex = useSelector(state => state?.basicDetailsReducer?.activeIndex);
  const [activeIndex, setActiveIndex] = useState(aIndex?.activeIndex >= 0 ? aIndex?.activeIndex : 1);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);
  const profileData = useSelector(state => state?.getProfileReducer?.payload?.data);

  useEffect(() => {
    getProfileApi();
  }, []);

  const getProfileApi = (res) => {
    dispatch(getProfileCallAPI(GET_PROFILE, {}, { run: true, navigate: navigate }));
  };


  const sidebarData = [
    {
      name: intl.formatMessage({ id: "MyProfile" }),
      icon: AccountIcon,
      selectedIcon: AccountIconColor,
      roles: ['Teacher', 'Student']
    },
    {
      name: intl.formatMessage({ id: "Assessment" }),
      icon: SubscriptionIcon,
      selectedIcon: SubscriptionIconColor,
      roles: ['Teacher', 'Student']
    },
    {
      name: intl.formatMessage({ id: "ManageSubscription" }),
      icon: AssesmentIcon,
      selectedIcon: AssesmentIconColor,
      roles: ['Teacher', 'Student']
    },
    {
      name: intl.formatMessage({ id: "Help" }),
      icon: HelpIcon,
      selectedIcon: HelpIconColor,
      roles: ['Teacher', 'Student']
    },
    {
      name: intl.formatMessage({ id: "Dashboard" }),
      icon: DashboardIcon,
      selectedIcon: DashboardIconColor,
      roles: ['Administrator']
    },
    {
      name: intl.formatMessage({ id: "User Management" }),
      icon: userManagementIcon,
      selectedIcon: userManagementIconColor,
      roles: ['Administrator'],
      children: [
        { name: intl.formatMessage({ id: "Parents/Students" }) },
        { name: intl.formatMessage({ id: "Teachers" }) },
        { name: intl.formatMessage({ id: "Internal Teachers" }) },
      ],
    },
    {
      name: intl.formatMessage({ id: "Category Management" }),
      icon: CategoryIcon,
      selectedIcon: CategoryIconColor,
      roles: ['Administrator']
    },
    {
      name: intl.formatMessage({ id: "Push Notifications" }),
      icon: NotificationIcon,
      selectedIcon: NotificationIconColor,
      roles: ['Administrator']
    },
    {
      name: intl.formatMessage({ id: "Manage Plans" }),
      icon: ManagerPlanIcon,
      selectedIcon: ManagerPlanIconColor,
      roles: ['Administrator']
    },
    {
      name: intl.formatMessage({ id: "Feedback & Support" }),
      icon: HelpIcon,
      selectedIcon: HelpIconColor,
      roles: ['Administrator']
    },
    {
      name: intl.formatMessage({ id: "Logout" }),
      icon: LogOutIcon,
      selectedIcon: LogOutIcon,
      roles: ['Teacher', 'Student', 'Administrator']
    },
  ];

  const handleSubmenuToggle = (text, index) => {
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
    setActiveIndex(null);
    dispatch(basicDetailsCallApi(ACTIVE_INDEX, { activeIndex: index }, { navigate: navigate }));
  };

  const handleSubmenuRedirect = (text, index) => {
    setActiveIndex(index);
    switch (text?.name) {
      case intl.formatMessage({ id: "Parents/Students" }):
        navigate(parentStudentUserManagement(locale));
        break;
      case intl.formatMessage({ id: "Teachers" }):
        navigate(teacherUserManagement(locale));
        break;
      case intl.formatMessage({ id: "Internal Teachers" }):
        navigate(internalTeacherUserManagement(locale));
        break;
    }
  }

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleMenuItemClick = (text, index) => {
    setOpenSubmenuIndex(null);
    switch (text?.name) {
      case intl.formatMessage({ id: "MyProfile" }):
        navigate(profile(locale));
        break;
      case intl.formatMessage({ id: "Assessment" }):
        navigate(teacherDashboard(locale));
        break;
      case intl.formatMessage({ id: "ManageSubscription" }):
        // navigate(questions(locale));
        break;
      case intl.formatMessage({ id: "Help" }):
        // navigate(questions(locale));
        break;
      case intl.formatMessage({ id: "Dashboard" }):
        navigate(adminDashboard(locale));
        break;
      case intl.formatMessage({ id: "Category Management" }):
        navigate(adminDashboard(locale));
        break;
      case intl.formatMessage({ id: "Push Notifications" }):
        navigate(adminDashboard(locale));
        break;
      case intl.formatMessage({ id: "Manage Plans" }):
        navigate(adminDashboard(locale));
        break;
      case intl.formatMessage({ id: "Feedback & Support" }):
        navigate(adminDashboard(locale));
        break;
      case intl.formatMessage({ id: "Logout" }):
        handleLogOut();
        break;
    }
    setActiveIndex(index);
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
    dispatch(basicDetailsCallApi(ACTIVE_INDEX, { activeIndex: index }, { navigate: navigate }));
  };

  const handleLogOut = () => {
    dispatch(authLogoutCallAPI(AUTH_LOGOUT, '', { run: true, handleFunction: (res) => handleRedirect(res), handleToastError: (res) => ToastErrorFunction(res), navigate: navigate, toast: true }));
  }

  const handleRedirect = (res) => {
    if (res?.data?.status_code === 200) {
      setConf({ msg: intl.formatMessage({ id: "logoutsuccessfully!" }), variant: 'success' });
      navigate(login(locale));
    } else {
      setConf({ msg: intl.formatMessage({ id: "Pleasetryagain." }), variant: 'error' });
    }
  };

  const ToastErrorFunction = (res) => {
    // setConf({ msg: "Invalid Credential !", variant: 'error' });
  };

  const filteredSidebarData = sidebarData.filter(item => item.roles.includes(userRole)); // Filter menu items by userRole

  const drawer = (
    <div>
      <Toolbar className={`flex-col justify-center pl-5 pt-5 pr-5 ${userRole === 'Administrator' ? 'pb-0' : 'pb-5'}`}>
        <p className='title m-0'><FormattedMessage id="MazadEdu" /></p>
        {userRole === 'Administrator' &&
          <>
            <Image className='mt-2 ' src={AdminProfileImage} alt={'image'} />
            <p className='m-0 mt-2 fs-16 fw-500 ff-InterMedium fc-1E1E1E'>{profileData?.data?.name}</p>
            <p className='m-0 mt-1 fs-14 fw-400 ff-InterRegular fc-A2A2A2'>{profileData?.data?.email}</p>
          </>
        }
      </Toolbar>

      <List className='sidebarList'>
        {filteredSidebarData.map((text, index) => (
          <div key={index}>
            <ListItem
              disablePadding
              className={text?.name === intl.formatMessage({ id: "Logout" }) ? 'sidebarBottom' : 'mt-3'}
            >
              <ListItemButton
                onClick={() => {
                  if (text.children) {
                    handleSubmenuToggle(text, index); // Toggle submenu
                  } else {
                    handleMenuItemClick(text, index);
                  }
                }}
                className={(activeIndex === index || (text.children && openSubmenuIndex === index)) ? 'active-menu-item' : ''}
              >
                <ListItemIcon>
                  <Image
                    src={(activeIndex === index || (text.children && openSubmenuIndex === index)) ? text?.selectedIcon : text?.icon}
                    alt={'icon'}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={text?.name}
                  className={
                    text?.name === intl.formatMessage({ id: "Logout" })
                      ? 'Item-logout'
                      : 'Item-text'
                  }
                />
              </ListItemButton>
            </ListItem>

            {/* Submenu */}
            {text.children && openSubmenuIndex === index && (
              <List className="submenuList">
                {text.children.map((subItem, subIndex) => (
                  <ListItem
                    key={`${index}-${subIndex}`}
                    className="ml-0"
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        handleSubmenuRedirect(subItem, `${index}-${subIndex}`);
                      }}
                      className={
                        activeIndex === `${index}-${subIndex}`
                          ? 'active-submenu-item'
                          : ''
                      }
                    >
                      <ListItemText className='Item-text' primary={subItem.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Row>
      <Col md={0} lg={2} className="sidebar">
        <Box sx={{ display: 'flex' }}>
          <AppBar>
            <Toolbar className='d-lg-none menu-container'>
              <Stack direction="horizontal" gap={4} className='d-flex'>
                <Image src={Menu} alt="Icon" className='menu-icon' onClick={handleDrawerToggle} />
                <p className="mb-0 menu-text"><FormattedMessage id="MazadEdu" /></p>
              </Stack>
            </Toolbar>
          </AppBar>

          <Box
            component="nav"
            aria-label="mailbox folders"
          >

            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              anchor={locale === 'en' ? 'left' : 'right'}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { md: 'block', lg: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: '300px'
                },
              }}
            >
              {drawer}
            </Drawer>

            <Drawer
              variant="permanent"
              anchor={locale === 'en' ? 'left' : 'right'}
              className='drawer-menu d-none d-lg-block'
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  borderRight: 'none'
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </Box>
      </Col>
    </Row>
  );
}

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;



