import { takeLatest, put } from "redux-saga/effects";
import {
    GET_TEACHER_AVAILABILITY,
    GET_TEACHER_AVAILABILITY_SUCCESS,
    GET_TEACHER_AVAILABILITY_ERROR,
} from "./constant";
import url from "services/urls.json";
import { getMethodWithToken } from "services/apiServices";
import { ClearTokenRedirectLogin } from "utils/general";

function* getTeacherAvailabilityApi(action) {

    try {
        const response = yield getMethodWithToken(url.getTeacherAvailability);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: GET_TEACHER_AVAILABILITY_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: GET_TEACHER_AVAILABILITY_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response);
            }
        }

    } catch (error) {
        yield put({ type: GET_TEACHER_AVAILABILITY_ERROR, payload: error });
    }
}

function* getTeacherAvailabilitySaga() {
    yield takeLatest(GET_TEACHER_AVAILABILITY, getTeacherAvailabilityApi);
}

export default getTeacherAvailabilitySaga;