import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/Admin/ParentStudentDetails/parentstudent.scss";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SwitchInput from "components/common/Input/SwitchInput";
import { getAllStudentsCallAPI } from "store/AdminDashboard/GetAllStudentLists/action";
import { GET_ALL_STUDENTS_LIST } from "store/AdminDashboard/GetAllStudentLists/constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { LocaleContext } from "routes/Router";
import CustomPopover from "components/common/CutomPopover";
import { UPDATE_USER_STATUS } from "store/AdminDashboard/UpdateUserStatus/constant";
import { updateUserStatusCallAPI } from "store/AdminDashboard/UpdateUserStatus/action";
import ToastNotification from "components/common/Toast";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const ParentStudentDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [conf, setConf] = ToastNotification();
    const { locale } = useContext(LocaleContext);
    const AllStudentList = useSelector(state => state?.getAllStudentsListReducer?.payload?.data);

    const [page, setPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page

    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const PopoverData = [
        {
            label: <FormattedMessage id="View" />,
            icon: <VisibilityOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => console.log('View clicked'),
        },
        {
            label: <FormattedMessage id="Edit" />,
            icon: <EditOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => console.log('Edit clicked'),
        },
        {
            label: <FormattedMessage id="Delete" />,
            icon: <DeleteOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => console.log('Delete clicked'),
        },
    ];

    useEffect(() => {
        getAllStudentLists(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const getAllStudentLists = (page, rowsPerPage) => {
        let params = { page, limit: rowsPerPage };
        dispatch(getAllStudentsCallAPI(GET_ALL_STUDENTS_LIST, { params }, { run: true, navigate: navigate }));
    };

    const handleSwitchChange = (event, item) => {
        let body = {
            "user_status": !item?.userstatus
        };
        dispatch(updateUserStatusCallAPI(UPDATE_USER_STATUS, body, { statusId: item?.id, run: true, navigate: navigate, handleFunction: (res) => handleSuccess(res) }));
    };

    const handleSuccess = (res) => {
        setConf({ msg: res?.data?.data, variant: 'success' });
        getAllStudentLists(page, rowsPerPage);
    }

    ////////// Pagination ////////////////////

    const totalItems = AllStudentList?.meta?.total || 0;
    const totalPages = Math.ceil(totalItems / rowsPerPage);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    ////////// Pagination ////////////////////


    return (
        <Row className="mt-5 mt-lg-0 pb-0 m-0">
            <h5 className="heading5 mt-2 mt-lg-0 p-0"><FormattedMessage id="Parents/students" /></h5>
            <Row className="page-parentstudent-contents mt-3 m-0 ">
                <div className="parent-student-conatiner">
                    <Row className="parent-student-list">
                        <Col md={2} className="align-center text-start">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">User Name</p>
                        </Col>
                        <Col md={2} className="align-center text-start">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Contact Number</p>
                        </Col>
                        <Col md={2} className="align-center text-start">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Email Address</p>
                        </Col>
                        <Col md={2} className="align-center text-center">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Date Added</p>
                        </Col>
                        <Col md={2} className="align-center text-center">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Status</p>
                        </Col>
                        <Col md={2} className="align-center text-center">
                            <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Actions</p>
                        </Col>
                    </Row>

                    {AllStudentList?.data?.length > 0 && AllStudentList?.data.map((item, index) =>
                        <Row className="parent-student-list">
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-16 fw-500 fc-1E1E1E ff-InterMedium">{item?.name}</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">{item?.phone}</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-375D84 ff-InterRegular">{item?.email}</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">{item?.date_joined}</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <SwitchInput
                                    label={false}
                                    checked={item?.userstatus}
                                    onChange={(e) => handleSwitchChange(e, item)}
                                    color="secondary"
                                />
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <MoreHorizIcon aria-describedby={id} style={{ fill: '#3C1053', cursor: "pointer" }} onClick={handlePopoverClick} />
                                <CustomPopover id={id} open={open} anchorEl={anchorEl} handleClose={handlePopoverClose} options={PopoverData} />
                            </Col>
                        </Row>
                    )}
                </div>
            </Row>

            <Row className="mt-3 m-0 p-0">
                <Stack spacing={2} className="p-0">
                    <Pagination
                        count={totalPages} 
                        page={page}       
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        className="d-flex justify-end"
                    />
                </Stack>
            </Row>

        </Row>
    );
};

export default ParentStudentDetails;
