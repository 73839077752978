import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'pages/Dashboard/TeacherDashboard/TeacherDashboard.scss';
import 'assets/styles/common.scss';
import StartExamScreen from 'components/feature/TeacherDashboard/StartExamScreen';
import StudentDetails from 'components/feature/Student/StudentDetails';

const StudentDashboard = () => {

    const [step, setStep] = useState(1);

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return <StudentDetails onClick={() => setStep(2)} />;
            default:
                return <StartExamScreen onClick={() => setStep(2)} />;
        }
    };

    return (
        <Container fluid className='dashboard_container'>
            <Row>
                <Col lg={2}>
                    {/* Empty space on the left */}
                </Col>
                <Col lg={9} className="d-flex align-items-center justify-content-center mt-lg-5 mb-lg-5">
                    {renderStepContent()}
                </Col>
                <Col lg={1}>
                    {/* Empty space on the right */}
                </Col>
            </Row>
        </Container>
    );
}

export default StudentDashboard;