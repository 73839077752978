import { takeLatest, put } from "redux-saga/effects";
import {
    GET_ALL_TEACHERS_LIST,
    GET_ALL_TEACHERS_LIST_SUCCESS,
    GET_ALL_TEACHERS_LIST_ERROR,
} from "./constant";
import url from "services/urls.json";
import { getMethodWithToken } from "services/apiServices";
import { ClearTokenRedirectLogin } from "utils/general";

function* getAllTeachersListApi(action) {

    try {
        const response = yield getMethodWithToken(url.getAllTeachersList + "?page=" + action?.data?.params?.page);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: GET_ALL_TEACHERS_LIST_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

            if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
        } else {
            yield put({ type: GET_ALL_TEACHERS_LIST_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                ClearTokenRedirectLogin(action?.external?.navigate);
            }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response);
            }
        }

    } catch (error) {
        yield put({ type: GET_ALL_TEACHERS_LIST_ERROR, payload: error });
    }
}

function* getAllTeachersListSaga() {
    yield takeLatest(GET_ALL_TEACHERS_LIST, getAllTeachersListApi);
}

export default getAllTeachersListSaga;