import React, { useState, useRef, useContext } from 'react';
import { Row, Col, Stack, Image, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import "components/feature/Teacher/ProfileSetup/ProfileSetup.scss";
import AvtarIcon from "assets/images/Registration/AvtarIcon.png";
import CameraIcon from "assets/images/Registration/cameraIcon.svg";
import LabelWithInput from 'components/common/Input/LabelWithInput';
import CheckValidation from 'components/feature/Teacher/ProfileSetup/validation';
import { successful } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';


const ProfileSetup = () => {

    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const fileInputRef = useRef();
    const [file, setFile] = useState(null);
    const [isError, setIsError] = useState(false);
    const [formValue, setFormValue] = useState({
        bio: ""
    });

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            // navigate("/successful");
            navigate(successful(locale));

        }
    };

    const handleUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const files = e.target.files[0]; // Get the first selected file
        if (files) {
            setFile(URL.createObjectURL(files)); // Create a URL for the file
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <Container className='container'>

            <Row className='pt-20  md:pt-120 mb-5'>
                <Row className='mb-5'>
                    <Col>
                        <h2 className='heading'>Let your students know you better</h2>
                    </Col>
                </Row>

                <Col xs={12} md={12} className='mt-2'>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        disabled={false}
                        style={{ display: 'none' }} // Hide the file input
                        onChange={handleFileChange}
                    />
                    <Stack direction="horizontal" gap={3} >
                        <Image src={file ? file : AvtarIcon} alt={"icon"} height={120} width={120} style={{ borderRadius: '50%' }} />
                        <div>
                            <Stack direction="horizontal" gap={2} className='fileUpload'>
                                <Image src={CameraIcon} alt={"icon"} onClick={handleUpload} />
                                <p className='mb-0' onClick={handleUpload}>Add Profile Picture</p>
                            </Stack>
                            <p className='mt-2 mb-0 profile_subheading'>A picture will help people recognize you</p>
                        </div>
                    </Stack>
                </Col>

                <Col xs={12} md={12} className='mt-4'>
                    <LabelWithInput
                        label={"Bio"}
                        placeholder={"Write something about yourself..."}
                        height="150"
                        multiline
                        rows={5}
                        name="bio"
                        value={formValue?.bio}
                        onChange={(e) => handleChange(e)}
                        errorText={isError && isError.bio.error && isError.bio.errorMessage}
                    />
                </Col>
            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'>Continue</Button>
            </Row>

        </Container>
    );
};

export default ProfileSetup;
