import React, { useContext, useState } from 'react';
import { Form, Row, Col, Image, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import "components/feature/Teacher/RegistrationForm/RegistrationForm.scss";
import ParentImage from "assets/images/Registration/parentIcon.png";
import ParentImageColor from "assets/images/Registration/parentIconColor.svg";
import StudentImage from "assets/images/Registration/studentIcon.svg";
import StudentImageColor from "assets/images/Registration/studentIconColor.png";
import TeacherImage from "assets/images/Registration/teacherIcon.png";
import TeacherImageColor from "assets/images/Registration/teacherIconColor.svg";
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import ToastNotification from 'components/common/Toast';
import { login, personalDetails } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage } from "react-intl";
import { useDispatch } from 'react-redux';
import { USER_ROLES } from 'store/BasicDetails/RegisterationDetails/constant';
import { UserRoles } from 'store/BasicDetails/RegisterationDetails/actions';

const RegistrationForm = () => {

    const { locale } = useContext(LocaleContext);
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState('student');
    const navigate = useNavigate();
    const [conf, setConf] = ToastNotification();

    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };

    const handleSubmit = (role) => {
        dispatch(UserRoles(USER_ROLES, role));
        navigate(personalDetails(locale));
    };

    const handleSignIn = () => {
        navigate(login(locale));
    };

    return (
        <Container>
            <Form className='pt-20  md:pt-120'>
                <Row className='mb-5'>
                    <Col>
                        <h2 className='heading'><FormattedMessage id="register.Heading" /></h2>
                    </Col>
                </Row>

                <Form.Group className={selectedOption === 'parent' ? 'selectedGroupBox' : 'groupBox'} onClick={() => handleOptionChange("parent")}>
                    <div className="d-flex align-items-center">
                        <Image src={selectedOption === 'parent' ? ParentImageColor : ParentImage} className={locale === "en" ? "mr-3" : "ml-3"} />
                        <div className="w-100">
                            <Form.Check
                                className={locale === "en" ? 'Checkbox' : 'CheckboxAr'}
                                reverse={locale === "en" ? true : false}
                                type="checkbox"
                                id="parent"
                                name="role"
                                label={<FormattedMessage id="register.ParentHeading" />}
                                value="parent"
                                checked={selectedOption === 'parent'}
                            // onChange={handleOptionChange}
                            />
                            <Form.Text muted><FormattedMessage id="register.ParentSubHeading" /></Form.Text>
                        </div>
                    </div>
                </Form.Group>

                <Form.Group className={selectedOption === 'student' ? 'selectedGroupBox my-5' : 'groupBox my-5'} onClick={() => handleOptionChange("student")}>
                    <div className="d-flex align-items-center">
                        <Image src={selectedOption === 'student' ? StudentImageColor : StudentImage} className={locale === "en" ? "mr-3" : "ml-3"} />
                        <div className="w-100">
                            <Form.Check
                                className={locale === "en" ? 'Checkbox' : 'CheckboxAr'}
                                reverse={locale === "en" ? true : false}
                                type="checkbox"
                                id="student"
                                name="role"
                                label={<FormattedMessage id="register.StudentHeading" />}
                                value="student"
                                checked={selectedOption === 'student'}
                            // onChange={handleOptionChange}
                            />
                            <Form.Text muted><FormattedMessage id="register.StudentSubHeading" /></Form.Text>
                        </div>
                    </div>
                </Form.Group>

                <Form.Group className={selectedOption === 'teacher' ? 'selectedGroupBox' : 'groupBox'} onClick={() => handleOptionChange("teacher")}>
                    <div className="d-flex align-items-center">
                        <Image src={selectedOption === 'teacher' ? TeacherImageColor : TeacherImage} className={locale === "en" ? "mr-3" : "ml-3"} />
                        <div className="w-100">
                            <Form.Check
                                className={locale === "en" ? 'Checkbox' : 'CheckboxAr'}
                                reverse={locale === "en" ? true : false}
                                type="checkbox"
                                id="teacher"
                                name="role"
                                label={<FormattedMessage id="register.TeacherHeading" />}
                                value="teacher"
                                checked={selectedOption === 'teacher'}
                            // onChange={handleOptionChange}
                            />
                            <Form.Text muted><FormattedMessage id="register.TeacherSubHeading" /></Form.Text>
                        </div>
                    </div>
                </Form.Group>

                <Row className='mb-5'>
                    <Col xs={12} md={12} className='d-flex justify-content-center mt-4'>
                        <p className='already_account'> <FormattedMessage id="register.Account" /> <span className='signup' onClick={handleSignIn}> <FormattedMessage id="register.SignIn" /> </span></p>
                    </Col>
                </Row>

                <Row className="footer pb-2">
                    <Button variant="contained"
                        onClick={() => handleSubmit(selectedOption)}
                        className='button'>
                        <FormattedMessage id="register.Continue" />
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

export default RegistrationForm;
