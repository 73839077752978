import React from 'react';
import { Stack } from 'react-bootstrap';
import Button from '@mui/material/Button';

const SrtartExamScreen = ({ onClick }) => {

    const handleRedirect = () => {
        onClick();
    };

    return (
        <div className="main-content flex text-center flex-col align-items-center justify-content-center page-content p-4 mt-5 mt-lg-0 pb-5 min-h-[89vh]">
            <h2>Thank you for registering with us!</h2>
            <p>
                For you to become a verified teacher, our AI has generated an exam to test.
                We recommend going through the test instructions.
            </p>

            <Stack direction="horizontal" gap={2} className='d-flex justify-content-center mt-4'>
                <Button variant="contained" className='button-outline'>Read Instructions</Button>
                <Button variant="contained" className='button' onClick={() => handleRedirect()}>Start the exam</Button>
            </Stack>
        </div>
    );
};

export default SrtartExamScreen;