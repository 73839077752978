import React, { useState } from 'react';
import 'assets/styles/common.scss';

const StudentDetails = ({ onClick }) => {

    return (
        <div className="main-content flex text-center flex-col align-items-center justify-content-center page-content p-4 mt-5 mt-lg-0 pb-5 min-h-[89vh]">
            <h2 className='heading2'>Student Dashboard</h2>
        </div>
    );
};

export default StudentDetails;