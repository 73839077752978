import globalAr from "utils/locale/common/ar.json";
import registerAr from "utils/locale/register/ar.json";
import loginAr from "utils/locale/login/ar.json";
import profileAr from "utils/locale/Profile/ar.json";
import teacherDashboardAr from "utils/locale/teacherDashboard/ar.json";

export default function TranslationsAr() {
    return {
        ...globalAr,
        ...registerAr,
        ...loginAr,
        ...profileAr,
        ...teacherDashboardAr
    };
}