import React, { useContext, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import 'assets/styles/common.scss';
import CheckValidation from 'components/feature/Teacher/LoginScreen/validation';
import { useDispatch } from 'react-redux';
import ToastNotification from 'components/common/Toast';
import { LocaleContext } from 'routes/Router';
import { adminLogin, register, reset, teacherDashboard } from 'routes/AppUrls';
import { AUTH_LOGIN } from 'store/Auth/Login/constant';
import { authLoginCallAPI } from 'store/Auth/Login/action';
import { FormattedMessage, useIntl } from "react-intl";

const LoginScreen = () => {

    const navigate = useNavigate();
    const intl = useIntl();
    const { locale } = useContext(LocaleContext);
    const [conf, setConf] = ToastNotification();
    const dispatch = useDispatch();
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [isError, setIsError] = useState(false);
    const location = useLocation();

    const [formValue, setformValue] = useState({
        email: "",
        password: ""
    });

    const handleSignup = () => {
        navigate(register(locale));
    };

    const handleForgot = () => {
        navigate(reset(locale));
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            let body = {
                "email": formValue?.email,
                "password": formValue?.password
            };
            dispatch(authLoginCallAPI(AUTH_LOGIN, body, { run: true, handleFunction: (res) => handleRedirect(res), handleToastError: (res) => ToastErrorFunction(res), navigate: navigate, toast: true }));
        }
    };

    const ToastErrorFunction = (res) => {
        setConf({ msg: intl.formatMessage({ id: "InvalidCredential!" }), variant: 'error' });
    };

    const handleRedirect = (res) => {
        if (res?.data?.status_code === 200 && res?.data?.data !== null) {
            setConf({ msg: intl.formatMessage({ id: "loginsuccessfully!" }), variant: 'success' });
            navigate(teacherDashboard(locale));
        } else {
            setConf({ msg: intl.formatMessage({ id: "Invalidcredentials.Pleasetryagain." }), variant: 'error' });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

  
    return (
        <Container>

            <Row className='pt-20  md:pt-120 mb-5'>
                <Row className='mb-5'>
                    <Col>
                        <h2 className='heading2'><FormattedMessage id="Login" /></h2>
                    </Col>
                </Row>

                <Col xs={12} md={12} className='mt-4'>
                    <LabelWithInput
                        label={<FormattedMessage id="register.EmailId" />}
                        placeholder={intl.formatMessage({ id: "register.EmailId" })}
                        name="email"
                        type="text"
                        value={formValue?.email}
                        onChange={handleInputChange}
                        errorText={isError && isError.email.error && isError.email.errorMessage}
                    />
                </Col>

                <Col xs={12} md={12} className='mt-3'>
                    <LabelWithInput
                        label={<FormattedMessage id="register.Password" />}
                        placeholder={intl.formatMessage({ id: "register.Password" })}
                        name="password"
                        value={formValue?.password}
                        type={isPassVisible ? "text" : "password"}
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {isPassVisible ? (
                                        <VisibilityOutlinedIcon sx={{ cursor: "pointer", color: '#911E59' }} onClick={() => setIsPassVisible(!isPassVisible)} />
                                    ) : (
                                        <VisibilityOffOutlinedIcon sx={{ cursor: "pointer", color: '#911E59' }} onClick={() => setIsPassVisible(!isPassVisible)} />
                                    )}
                                </>
                            )
                        }}
                        errorText={isError && isError.password.error && isError.password.errorMessage}
                    />
                </Col>

                {location.pathname !== adminLogin(locale) &&
                    <>
                        <Col xs={12} md={12} className='d-flex justify-content-end mt-4'>
                            <p className='resend forgot' onClick={handleForgot}><FormattedMessage id="ForgotPassword" /></p>
                        </Col>

                        <Col xs={12} md={12} className='d-flex justify-content-center mt-4'>
                            <p className='already_account'><FormattedMessage id="login.DoNotHaveAnAccount" /> <span className='signup' onClick={handleSignup}><FormattedMessage id="SignUp" /></span></p>
                        </Col>
                    </>
                }

            </Row>

            <Row className="footer pb-2">
                <Button variant="contained" onClick={() => handleSubmit()} className='button'><FormattedMessage id="register.Continue" /></Button>
            </Row>
        </Container>
    );
};

export default LoginScreen;
