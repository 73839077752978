import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Row, Col, Button, Stack, Image } from 'react-bootstrap';
import "components/feature/MyCalendar/myCalendar.scss";
import dotImage from "assets/images/Availability/dotImage.svg";
import dotImageColor from "assets/images/Availability/dotImageColor.svg";
import addIcon from "assets/images/addIconColor.svg";
import ArrowRightIcon from "assets/images/Availability/ArrowRightIcon.svg";
import ArrowLeftIcon from "assets/images/Availability/ArrowLeftIcon.svg";
import CommonModal from 'components/common/Modal';
import { Box } from "@mui/system";
import crossIcon from "assets/images/crossIcon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import DateTimePickerInput from 'components/common/DateTimePickerInput';
import CheckBoxInput from 'components/common/Input/CheckBoxInput';
import { useDispatch, useSelector } from 'react-redux';
import { getTeacherAvailabilityCallAPI } from 'store/Dashboard/GetTeacherAvailability/action';
import { GET_TEACHER_AVAILABILITY } from 'store/Dashboard/GetTeacherAvailability/constant';
import { useNavigate } from 'react-router-dom';
import { addTeacherAvailabilityCallAPI } from 'store/Dashboard/AddTeacherAvailability/action';
import { ADD_TEACHER_AVAILABILITY } from 'store/Dashboard/AddTeacherAvailability/constant';
import ToastNotification from 'components/common/Toast';
import SelectInput from 'components/common/Input/SelectInput';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import { repeatFrequenctDropDownCallAPI } from 'store/Dashboard/Availability/RepeatFrequency/action';
import { REPEAT_FREQUENCY_DROPDOWN } from 'store/Dashboard/Availability/RepeatFrequency/constant';
import { WEEEK_DAYS_DROPDOWN } from 'store/Dashboard/Availability/WeekDays/constant';
import { weekDaysDropDownCallAPI } from 'store/Dashboard/Availability/WeekDays/action';
import Popover from '@mui/material/Popover';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { LocaleContext } from 'routes/Router';
import Typography from '@mui/material/Typography';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getInterviewFeedbackCallAPI } from 'store/Dashboard/InterViewFeedback/action';
import { INTERVIEW_FEEDBACK } from 'store/Dashboard/InterViewFeedback/constant';
import dayjs from 'dayjs'; // Import dayjs
import { editTeacherAvailabilityCallAPI } from 'store/Dashboard/EditTeacherAvailability/action';
import { EDIT_TEACHER_AVAILABILITY } from 'store/Dashboard/EditTeacherAvailability/constant';
import 'assets/styles/common.scss';
import { deleteTeacherAvailabilityCallAPI } from 'store/Dashboard/DeleteTeacherAvailability/action';
import { DELETE_TEACHER_AVAILABILITY } from 'store/Dashboard/DeleteTeacherAvailability/constant';


const localizer = momentLocalizer(moment);

const MyCalendar = () => {
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [viewData, setViewData] = useState("");
    const intl = useIntl();
    const { locale } = useContext(LocaleContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [anchorEl, setAnchorEl] = useState(null);
    const [conf, setConf] = ToastNotification();
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [availableDate, setAvailableDate] = useState(null);
    const [repeatEndDate, setRepeatEndDate] = useState(null);
    const [availableTime, setAvailableTime] = useState(null);
    const [feedbackDescription, setFeedbackDescription] = useState("");
    const [selectOutcome, setSelectOutcome] = useState("");

    const [tillTime, setTillTime] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [repeat, setRepeat] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [repeatFrequency, setRepeatFrequency] = useState("Day");
    const [repeatEvery, setRepeatEvery] = useState(1);
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);
    const [selectedWeekDaysId, setSelectedWeekDaysId] = useState([]);
    const currentDate = moment().format('ddd, MMM D');

    const getTeacherAvailabilityData = useSelector(state => state?.getTeacherAvailabilityReducer?.payload?.data);
    const repeatFrequencyList = useSelector(state => state?.repeatFrequencyDropDownReducer?.payload?.data?.data);
    const weekList = useSelector(state => state?.weekDaysDropDownReducer?.payload?.data?.data);

    const interviewOutcomeList = [{ name: intl.formatMessage({ id: "Passed" }), id: 1 }, { name: intl.formatMessage({ id: "Failed" }), id: 2 }];
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        getTeacherAvailability();
    }, []);

    const getTeacherAvailability = () => {
        dispatch(getTeacherAvailabilityCallAPI(GET_TEACHER_AVAILABILITY, {}, { run: true, navigate: navigate }));
    };


    const generateRepeatedEvents = (event) => {
        const repeatedEvents = [];
        const repeatFrequency = event.repeatFrequency; // e.g., "Day", "Week", "Month"
        const repeatEvery = event.repeatEvery; // count Interval (e.g., every 1 day, every 2 weeks, etc.)
        const repeatOn = event.repeatOn; // Array of weekdays (e.g., ['Monday', 'Thursday'])
        const repeatEndDate = event.repeatEndDate ? moment(event.repeatEndDate).add(1, 'day') : null;

        let currentStartDate = moment(event.start);
        let currentEndDate = moment(event.end);

        while (currentStartDate.isSameOrBefore(repeatEndDate)) {
            // If it's a weekly repeat and specific days are provided
            if (repeatFrequency === "Week" && repeatOn && repeatOn.length > 0) {
                repeatOn.forEach(day => {
                    const dayIndex = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
                    let nextDay = currentStartDate.clone().day(dayIndex); // Get the next occurrence of this day

                    // If the day is before the current start date, go to the next occurrence in the next week
                    if (nextDay.isBefore(currentStartDate)) {
                        nextDay = nextDay.add(1, 'weeks');
                    }

                    // Ensure the event falls within the repeat range
                    if (nextDay.isSameOrBefore(repeatEndDate) && nextDay.isSameOrAfter(currentStartDate)) {
                        repeatedEvents.push({
                            ...event,
                            start: nextDay.toDate(),
                            end: nextDay.clone().add(currentEndDate.diff(currentStartDate)).toDate(), // Adjust end time
                        });
                    }
                });
                currentStartDate.add(repeatEvery, "weeks"); // Move to the next repeat interval (weeks)
                currentEndDate.add(repeatEvery, "weeks");
            } else {
                repeatedEvents.push({
                    ...event,
                    start: currentStartDate.toDate(),
                    end: currentEndDate.toDate(),
                });

                // Adjust the next event's date based on frequency
                switch (repeatFrequency) {
                    case "Day":
                        currentStartDate.add(repeatEvery, "days");
                        currentEndDate.add(repeatEvery, "days");
                        break;
                    case "Month":
                        currentStartDate.add(repeatEvery, "months");
                        currentEndDate.add(repeatEvery, "months");
                        break;
                    default:
                        break;
                }
            }
        }

        return repeatedEvents;
    };

    useEffect(() => {
        if (getTeacherAvailabilityData) {
            const formattedEvents = getTeacherAvailabilityData?.data?.length > 0 ?
                getTeacherAvailabilityData?.data.map(item => {
                    const start = combineDateTime(item?.available_date, item?.available_time);
                    const end = combineDateTime(item?.available_date, item?.available_end_time);

                    const baseEvent = {
                        id: item?.id,
                        start: start,
                        end: end,
                        teacher_name: item?.teacher_name,
                        interview_link: item?.interview_link,
                        interview_time: item?.interview_time,
                        status: item?.status,
                        status_id: item?.status?.id,
                        subject_id: item?.subject_id,
                        teacher_id: item?.new_teacher_id,
                        grade_id: item?.grade_id,
                        teaching_material_id: item?.teaching_material_id,
                        allDay: false
                    };

                    // If the event has a repeat pattern, generate repeat events for all reapeatdata items
                    if (item?.reapeatdata?.length > 0) {
                        return item?.reapeatdata.flatMap(repeatItem => {
                            const repeatEvent = {
                                ...baseEvent,
                                repeatFrequency: repeatItem?.frequency, // e.g., "Daily", "Weekly", "Monthly"
                                repeatEvery: repeatItem?.repeat_every || 1, // Interval (default to 1 if not provided)
                                repeatEndDate: repeatItem?.ends_on, // End date for the repeat series
                                repeatOn: repeatItem?.repeat_on ? [repeatItem?.repeat_on] : [], // Array of repeat days if provided
                            };
                            return generateRepeatedEvents(repeatEvent); // Generate repeat events for this pattern
                        });
                    } else {
                        return [baseEvent]; // If no repeat, just return the base event
                    }
                })
                :
                [];

            // Flatten the array of arrays
            setEvents(formattedEvents.flat());
        } else {
            setEvents([]); // Ensure events is always an array
        }
    }, [getTeacherAvailabilityData]);


    const handleSelectSlot = (slotInfo) => {
        setSelectedSlot(slotInfo);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditModel(false);
    };

    function combineDateTime(dateStr, timeStr) {
        const [year, month, day] = dateStr.split('-').map(Number); // Split and convert date string
        const [hours, minutes] = timeStr.split(/[: ]/).map(Number); // Split and convert time string
        const isPM = timeStr.toLowerCase().includes('pm'); // Check if it's PM
        const finalHours = isPM && hours < 12 ? hours + 12 : hours;
        return new Date(year, month - 1, day, finalHours, minutes); // Create new Date object
    };

    const handleSaveEvent = () => {
        if (!availableDate || !availableTime || !tillTime) {
            return setConf({ msg: intl.formatMessage({ id: "Pleasefillallfields!" }), variant: 'error' });
        }

        const formattedDate = moment(availableDate).format('YYYY-MM-DD');
        const formattedStartTime = moment(availableTime).format('hh:mm a');
        const formattedEndTime = moment(tillTime).format('hh:mm a');

        let body = {
            available_date: formattedDate,
            available_time: formattedStartTime,
            available_end_time: formattedEndTime,
            repeat_availability: repeat ? 1 : 0,
        };

        if (repeat) {
            if (!repeatEndDate) {
                return setConf({ msg: intl.formatMessage({ id: "Pleasefillallfields!" }), variant: 'error' });
            }
            body = {
                ...body,
                repeat_every: repeatEvery,
                frequency: repeatFrequency,
                repeat_ends: "On",
                ends_on: moment(repeatEndDate).format('YYYY-MM-DD'),
                ...(repeatFrequency === "Week" && { repeat_on: selectedWeekDaysId })
            };
        }

        if (editModel) {
            dispatch(editTeacherAvailabilityCallAPI(EDIT_TEACHER_AVAILABILITY, body, { editId: viewData?.id, run: true, handleFunction: handleSuccess, navigate: navigate, toast: true, handleToastError: handleError }));
        } else {
            dispatch(addTeacherAvailabilityCallAPI(ADD_TEACHER_AVAILABILITY, body, { run: true, handleFunction: handleSuccess, navigate: navigate, toast: true, handleToastError: handleError }));
        }

    };

    const handleSuccess = (res) => {
        setConf({ msg: (res?.data?.message), variant: 'success' });
        getTeacherAvailability();
        setShowModal(false);
    };

    const handleError = (res) => {
        setConf({ msg: (res?.data?.message?.available_date?.[0] || res?.data?.message?.available_end_time?.[0] || res?.data?.message), variant: 'error' });
    };

    // Custom Toolbar with navigation and Add Availability button
    const CustomToolbar = ({ onNavigate, label }) => {
        return (
            <div className="rbc-toolbar p-4">
                <span className="rbc-btn-group">
                    {currentDate}
                </span>
                <span className="rbc-toolbar-label flex justify-center">
                    {locale === "en" ?
                        <img src={ArrowLeftIcon} className='me-3' alt='icon' style={{ cursor: "pointer" }} onClick={() => onNavigate('PREV')} />
                        :
                        <img src={ArrowRightIcon} className='me-3' alt='icon' style={{ cursor: "pointer" }} onClick={() => onNavigate('NEXT')} />
                    }
                    <span style={{ width: "150px" }}>
                        {label}
                    </span>
                    {locale === "en" ?
                        <img src={ArrowRightIcon} className='ms-3' alt='icon' style={{ cursor: "pointer" }} onClick={() => onNavigate('NEXT')} />
                        :
                        <img src={ArrowLeftIcon} className='ms-3' alt='icon' style={{ cursor: "pointer" }} onClick={() => onNavigate('PREV')} />
                    }

                </span>

                <span className="rbc-btn-group">
                    <Stack direction="horizontal" gap={2} onClick={handleShowModal} >
                        <Image src={addIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} />
                        <p className='mb-0 buttonSelector' style={{ color: "#911E59", cursor: "pointer" }}><FormattedMessage id="AddAvailability" /></p>
                    </Stack>
                </span>
            </div>
        );
    };

    // Custom event component to display in the calendar
    const EventComponent = ({ event }) => {
        return (
            <span>
                <span className='eventTitle'>
                    {event?.status?.slug !== "available-slot" ?
                        <img src={dotImageColor} alt="icon" className='me-1' />
                        :
                        <img src={dotImage} alt="icon" className='me-1' />
                    }
                    {event?.status?.name}
                </span>
                <span className='eventTime'>
                    {moment(event?.start).format('hh:mm a')} - {moment(event?.end).format('hh:mm a')}
                </span>
            </span>
        );
    };

    // When a day is selected, update the selected date
    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setSelectedDate(moment(event.start).startOf('day'));
    };

    // Filter events based on the selected date
    const eventsForSelectedDate = events?.filter(event => {
        return selectedDate && moment(event?.start).isSame(selectedDate, 'day');
    });

    const handleShowModal = () => {
        setAvailableDate(null);
        setAvailableTime(null);
        setRepeatEndDate(null);
        setSelectedWeekDays([]);
        setSelectedWeekDaysId([]);
        setRepeatEvery(1);
        setRepeatFrequency("Day");
        setTillTime(null);
        setRepeat(false);
        setShowModal(true);
    };

    // Disable drill down (view change to day/week view) for certain dates
    const handleDrillDown = (date, view) => {
        return true;
    };

    const handleInputChange = (e) => {
        if (e?.target?.value?.name === "Week") {
            getWeekListData();
        }
        setRepeatFrequency(e?.target?.value?.name);
    };

    const handleChange = (e) => {
        setRepeatEvery(e?.target?.value);
    };

    const handleSelectWeekDays = (item) => {
        if (selectedWeekDays.includes(item?.name)) {
            setSelectedWeekDays((prev) => prev.filter((day) => day !== item?.name));
            setSelectedWeekDaysId((prev) => prev.filter((day) => day !== item?.id));
        } else {
            setSelectedWeekDays((prev) => [...prev, item?.name]);
            setSelectedWeekDaysId((prev) => [...prev, item?.id]);
        }
    };

    const handleCheckBox = (e) => {
        setRepeat(e.target.checked);
        getRepeatFrequencyDropDown();
    };

    const getRepeatFrequencyDropDown = () => {
        let body = {
            "slug": "repeat-frequency"
        };
        dispatch(repeatFrequenctDropDownCallAPI(REPEAT_FREQUENCY_DROPDOWN, body, { run: true, navigate: navigate }));
    };

    const getWeekListData = () => {
        let body = {
            "slug": "week-days"
        };
        dispatch(weekDaysDropDownCallAPI(WEEEK_DAYS_DROPDOWN, body, { run: true, navigate: navigate }));
    };

    const handleSchedule = (event, item) => {
        setViewData(item);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleJoinMeeting = (data) => {
        window.open(data?.interview_link);
    };

    const repeatData = () => {
        switch (repeatEvery) {
            case "1":
                return repeatEvery + "st";
            case "2":
                return repeatEvery + "nd";
            case "3":
                return repeatEvery + "rd";
            default:
                return repeatEvery + "th";
        }
    };

    const handleViewModel = () => {
        setSelectOutcome("");
        setFeedbackDescription("");
        setViewModal(true);
    };

    const handleEditModel = () => {
        setEditModel(true);
        setRepeat(false);
        setShowModal(true);
        setAvailableDate(viewData.start);
        setAvailableTime(viewData?.start);
        setTillTime(viewData?.end);

        if (viewData?.repeatFrequency) {
            setRepeat(true);
            setRepeatFrequency(viewData?.repeatFrequency);
            setRepeatEvery(viewData?.repeatEvery);
            setRepeatEndDate(viewData?.repeatEndDate);
        }
    };

    const handleDeleteModel = () => {
        dispatch(deleteTeacherAvailabilityCallAPI(DELETE_TEACHER_AVAILABILITY, {}, { deleteId: viewData?.id, handleFunction: handleSuccess, run: true, navigate: navigate }));
    };

    const handleSelectOutcome = (data) => {
        setSelectOutcome(data?.name);
    };

    const handleFeedbackApi = (data) => {
        if (!feedbackDescription || !selectOutcome) {
            return setConf({ msg: intl.formatMessage({ id: "Pleasefillfield!" }), variant: 'error' });
        }
        let body = {
            "teacher_id": data?.teacher_id,
            "teacher_subject_id": data?.subject_id,
            "grade_id": data?.grade_id,
            "teaching_material_id": data?.teaching_material_id,
            "status_id": selectOutcome === "Passed" ? 71 : 72,
            "feedback": feedbackDescription
        }
        dispatch(getInterviewFeedbackCallAPI(INTERVIEW_FEEDBACK, body, { run: true, handleFunction: (res) => handleSuccessFeedback(res), navigate: navigate }));
    };

    const handleSuccessFeedback = (res) => {
        setConf({ msg: intl.formatMessage({ id: "feedbackupdatedsuccessfully!" }), variant: 'success' });
        setFeedbackModal(false);
    };

    return (
        <>
            <Row className="page-content mt-3 m-0 p-0 mb-3 mb-lg-0">
                <Col md={8} className='m-0 p-0' style={{ background: "white", borderRadius: "8px" }}>
                    <div className="calendar-wrapper">
                        <Calendar
                            className={locale === "en" ? "eventEn" : "eventAr"}
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 600 }}
                            components={{
                                toolbar: CustomToolbar,
                                event: EventComponent, // Display custom event component
                            }}
                            onSelectEvent={handleSelectEvent}
                            selectable
                            onSelectSlot={handleSelectSlot}
                            onDrillDown={handleDrillDown}
                            // Pass the selectedSlot state to a custom `dayPropGetter` to apply styling
                            dayPropGetter={(date) => {
                                const isSelectedEvent = selectedEvent && moment(date).isSame(selectedEvent.start, 'day');
                                return {
                                    className: isSelectedEvent ? 'selected-date' : ''
                                };
                            }}
                        />
                    </div>
                </Col>

                <Col md={4} className='scheduleSlot' style={{ background: "white", borderRadius: "8px" }}>
                    <ul className='pt-4'>
                        <h3 className='schdeuleHeading'><FormattedMessage id="Schedule" /> {selectedEvent && moment(selectedEvent?.start).format('DD MMMM')}</h3>
                        {eventsForSelectedDate?.length > 0 ? (
                            eventsForSelectedDate.map((event, index) => (
                                <li key={index} className='eventListSchdeule'>
                                    <div>
                                        <span className='eventScheduleTitle'>
                                            {event?.status?.slug !== "available-slot" ?
                                                <img src={dotImageColor} alt="icon" className='me-1' />
                                                :
                                                <img src={dotImage} alt="icon" className='me-1' />
                                            }
                                            <span>{event?.status?.name}</span>
                                        </span>
                                        <div className='eventDateTime'>
                                            {moment(event?.start).format('hh:mm a')} - {moment(event?.end).format('hh:mm a')}
                                        </div>
                                    </div>
                                    <div>
                                        <MoreVertOutlinedIcon aria-describedby={id} style={{ marginLeft: locale == 'en' && '20px', marginRight: locale == 'ar' && '20px', fill: '#3C1053', cursor: "pointer" }} onClick={(e) => handleSchedule(e, event)} />
                                    </div>
                                </li>
                            ))
                        ) : (
                            events?.length > 0 ? events.map((event, index) => (
                                <li key={index} className='eventListSchdeule' >
                                    <div>
                                        <span className='eventScheduleTitle'>
                                            {event?.status?.slug !== "available-slot" ?
                                                <img src={dotImageColor} alt="icon" className='me-1' />
                                                :
                                                <img src={dotImage} alt="icon" className='me-1' />
                                            }
                                            <span>{event?.status?.name}</span>
                                        </span>
                                        <div className='eventDateTime'>
                                            {moment(event?.start).format('DD MMMM, hh:mm a')} -{' '}
                                            {moment(event?.end).format('hh:mm a')}
                                        </div>
                                    </div>
                                    <div>
                                        <MoreVertOutlinedIcon aria-describedby={id} style={{ marginLeft: locale == 'en' && '20px', marginRight: locale == 'ar' && '20px', fill: '#3C1053', cursor: "pointer" }} onClick={(e) => handleSchedule(e, event)} />
                                    </div>
                                </li>
                            ))
                                :
                                <li><FormattedMessage id="Noeventsschdeuled." /></li>
                        )}
                    </ul>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Typography sx={{ pt: 1, pl: 2, pr: 2, pb: 1 }} className='group'>
                            <div className='flex items-end group' style={{ cursor: "pointer" }} onClick={() => handleViewModel()}>
                                <VisibilityOutlinedIcon style={{ marginRight: '5px', marginTop: "-5px", cursor: "pointer" }} className='group' />
                                <p className='m-0 group'><FormattedMessage id="View" /></p>
                            </div>
                        </Typography>
                        {viewData?.status?.slug === "available-slot" &&
                            <>
                                <Typography sx={{ pt: 1, pl: 2, pr: 2, pb: 1 }} className='group'>
                                    <div className='flex items-end group' style={{ cursor: "pointer" }} onClick={() => handleEditModel()}>
                                        <EditOutlinedIcon style={{ marginRight: '5px', marginTop: "-5px", cursor: "pointer" }} className='group' />
                                        <p className='m-0 group'><FormattedMessage id="Edit" /></p>
                                    </div>
                                </Typography>
                                <Typography sx={{ pt: 1, pl: 2, pr: 2, pb: 2 }} className='group'>
                                    <div className='flex items-end group' style={{ cursor: "pointer" }} onClick={() => handleDeleteModel()}>
                                        <DeleteOutlinedIcon style={{ marginRight: '5px', marginTop: "-5px", cursor: "pointer" }} className='group' />
                                        <p className='m-0 group'><FormattedMessage id="Delete" /></p>
                                    </div>
                                </Typography>
                            </>
                        }
                    </Popover>
                </Col>
            </Row>

            {/* Add  Modal  */}
            <CommonModal padding={'32px 48px'} width={'500px'} modalActive={showModal} handleClose={() => handleCloseModal()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2">{editModel ? <FormattedMessage id="EditAvailability" /> : <FormattedMessage id="AddAvailability" />}</h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseModal()} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-2'>
                                <DateTimePickerInput
                                    datepicker={true}
                                    label={<FormattedMessage id="Date" />}
                                    value={availableDate ? dayjs(availableDate) : null}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setAvailableDate(newValue.toDate());
                                        }
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-2'>
                                <DateTimePickerInput
                                    timepicker={true}
                                    label={<FormattedMessage id="AvailableFrom" />}
                                    value={availableTime ? dayjs(availableTime) : null}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setAvailableTime(newValue.toDate());
                                        }
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-2'>
                                <DateTimePickerInput
                                    timepicker={true}
                                    label={<FormattedMessage id="Till" />}
                                    value={tillTime ? dayjs(tillTime) : null}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setTillTime(newValue.toDate());
                                        }
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={12} className='mt-2'>
                                <CheckBoxInput
                                    label={<FormattedMessage id="Repeat" />}
                                    size="16"
                                    fontWeight="500"
                                    onChange={(e) => handleCheckBox(e)}
                                    checked={repeat}
                                />
                            </Col>

                            {repeat === true &&
                                <>
                                    <Col xs={4} md={4} className='mt-2'>
                                        <LabelWithInput
                                            label={<FormattedMessage id="RepeatEvery" />}
                                            placeholder={intl.formatMessage({ id: "RepeatEvery" })}
                                            name="repeatEvery"
                                            type="number"
                                            value={repeatEvery}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </Col>

                                    <Col xs={8} md={8} className='mt-2'>
                                        <SelectInput
                                            label={<FormattedMessage id="RepeatFrequency" />}
                                            placeholder={intl.formatMessage({ id: "RepeatFrequency" })}
                                            name={"repeatFrequency"}
                                            value={repeatFrequency}
                                            data={repeatFrequencyList}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </Col>

                                    {repeatFrequency === "Day" &&
                                        <Col xs={12} md={12} className='mt-2'>
                                            <p className='m-0'><FormattedMessage id="Availabilitysettodaily" /></p>
                                        </Col>
                                    }

                                    {repeatFrequency === "Week" &&
                                        <Col xs={12} md={12} className='mt-2'>
                                            <div className='RepeatOn mt-2'>
                                                <FormattedMessage id="RepeatsOn" />
                                            </div>
                                            <div className='flex weekFrequency justify-between mt-2'>
                                                {weekList && weekList?.map((item, index) => (
                                                    <p key={index} className={selectedWeekDays.includes(item?.name) ? 'weekItemSelected' : 'weekItem'} onClick={() => handleSelectWeekDays(item)}>{item?.name?.substring(0, 1)}</p>
                                                ))}
                                            </div>

                                            {selectedWeekDays?.length > 0 &&
                                                <p className='m-0'><FormattedMessage id="Availabilitysettoeveryweekon" /> {" "}
                                                    {selectedWeekDays.map((item, index) => (
                                                        <span key={index} className="me-2">
                                                            {index === selectedWeekDays.length - 2
                                                                ? `${item?.substring(0, 3)} &` // Before the last item, add '&'
                                                                : index === selectedWeekDays.length - 1
                                                                    ? item?.substring(0, 3) // Last item, no comma or '&' after it
                                                                    : `${item?.substring(0, 3)},` // Add a comma after each item except the last two
                                                            }
                                                        </span>
                                                    ))}
                                                </p>
                                            }
                                        </Col>
                                    }

                                    {repeatFrequency === "Month" &&
                                        <Col xs={12} md={12} className='mt-2'>
                                            <p className='m-0'><FormattedMessage id="Availabilitysetto" /> {repeatData()} <FormattedMessage id="ofeverymonth" /></p>
                                        </Col>
                                    }

                                    <Col xs={12} md={12} className='mt-2'>
                                        <DateTimePickerInput
                                            datepicker={true}
                                            label={<FormattedMessage id="EndDate" />}
                                            value={repeatEndDate ? dayjs(repeatEndDate) : null}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    setRepeatEndDate(newValue.toDate());
                                                }
                                            }}
                                        />
                                    </Col>
                                    {/* } */}

                                </>
                            }

                        </Row>

                        <Row>
                            <Col xs={6} md={6} className=''>
                                <Button variant="outlined" className='cancelButton mt-4 md:mt-2 w-100' onClick={() => handleCloseModal()}><FormattedMessage id="Cancel" /></Button>
                            </Col>
                            <Col xs={6} md={6} className=''>
                                <Button variant="contained" className='button mt-4 md:mt-2 w-100' onClick={handleSaveEvent}><FormattedMessage id="Save" /></Button>
                            </Col>
                        </Row>
                    </Box>
                }
            />
            {/* Add Modal  */}

            {/* View  Modal  */}
            <CommonModal padding={'32px 48px'} width={'500px'} modalActive={viewModal} handleClose={() => setViewModal(false)}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id={viewData?.status?.name} /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => setViewModal(false)} />
                                </div>
                            </Col>

                            <Col xs={12} md={12}>
                                <div className='eventViewScheduleTitle mt-4'>
                                    {moment(viewData?.start).format('ddd, D MMMM')}
                                </div>
                                <div className='eventViewDateTime mt-3'>
                                    {moment(viewData?.start).format('hh:mm a')} -{' '}
                                    {moment(viewData?.end).format('hh:mm a')}
                                </div>
                            </Col>

                            {viewData?.status?.slug !== "available-slot" &&
                                <Stack direction="horizontal" gap={2} className='d-flex justify-content-center mt-5'>
                                    <Button variant="outlined" className='cancelButton w-full' onClick={() => setFeedbackModal(true)}><FormattedMessage id="InterviewFeedback" /></Button>
                                    <Button variant="contained" className='button w-full' onClick={() => handleJoinMeeting(viewData)}><FormattedMessage id="JoinMeeting" /></Button>
                                </Stack>
                            }
                        </Row>
                    </Box>
                }
            />
            {/* View Modal  */}

            {/* feedback  Modal  */}
            <CommonModal padding={'32px 48px'} width={'500px'} modalActive={feedbackModal} handleClose={() => setFeedbackModal(false)}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id="InterviewFeedback" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => setFeedbackModal(false)} />
                                </div>
                            </Col>

                            <Col xs={12} md={12}>
                                <div className='mt-4'>
                                    <p className='grey'><FormattedMessage id="Candidate" />{": "}<span className='eventViewDateTime'>{viewData?.status?.name?.replace("Interview with", "")?.trim()}</span></p>
                                </div>
                                <div className='eventViewDateTime mt-3'>
                                    <Col xs={12} md={12} className='mt-2'>
                                        <p className='mb-2 teacher_label'><FormattedMessage id="InterviewOutcome" /></p>
                                        <div className="flex flex-row items-center flex-wrap gap-3 endRepeatDate">
                                            {interviewOutcomeList && interviewOutcomeList?.length > 0 && interviewOutcomeList.map((k, i) => (
                                                <p className={selectOutcome === k?.name ? 'selectEndRepeatDate' : 'unSelectEndRepeatDate'} onClick={() => handleSelectOutcome(k)}>{k?.name}</p>
                                            ))}
                                        </div>
                                    </Col>
                                </div>
                            </Col>

                            <Col xs={12} md={12}>
                                <LabelWithInput
                                    label={<FormattedMessage id="Description" />}
                                    placeholder={intl.formatMessage({ id: "Description" })}
                                    height="150"
                                    multiline
                                    rows={6}
                                    name="feedbackDescription"
                                    value={feedbackDescription}
                                    onChange={(e) => setFeedbackDescription(e?.target?.value)}
                                />
                            </Col>

                            <Stack direction="horizontal" gap={2} className='d-flex justify-content-center mt-5'>
                                <Button variant="contained" className='button w-full' onClick={() => handleFeedbackApi(viewData)}><FormattedMessage id="SubmitFeedback" /></Button>
                            </Stack>
                        </Row>
                    </Box>
                }
            />
            {/* feedback Modal  */}
        </>
    );
};

export default MyCalendar;

