import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'pages/Dashboard/TeacherDashboard/TeacherDashboard.scss';
import 'assets/styles/common.scss';
import StartExamScreen from 'components/feature/TeacherDashboard/StartExamScreen';
import EmailVerification from 'components/feature/TeacherDashboard/EmailVerification';
import InterviewScreen from 'components/feature/TeacherDashboard/InterviewScreen';
import { GET_PROFILE } from 'store/Dashboard/Profile/constant';
import { getProfileCallAPI } from 'store/Dashboard/Profile/action';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { questions } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import ToastNotification from 'components/common/Toast';
import { ASSESSMENT_VERIFICATION_EMAIL } from 'store/Dashboard/AssessmentVerifiedEmail/constant';
import { assessmentVerificationCallAPI } from 'store/Dashboard/AssessmentVerifiedEmail/action';
import { getQuestionsCallAPI } from 'store/Dashboard/GetQuestions/action';
import { GET_QUESTIONS } from 'store/Dashboard/GetQuestions/constant';
import Assessment from 'components/feature/Assessment';
import { useIntl } from 'react-intl';

const TeacherDashboard = () => {

    const [step, setStep] = useState(1);
    const { locale } = useContext(LocaleContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [conf, setConf] = ToastNotification();
    const intl = useIntl();

    const profileData = useSelector(state => state?.getProfileReducer?.payload?.data);
    const ResendLoader = useSelector(state => state?.assessmentVerificationReducer?.loading);


    useEffect(() => {
        getProfileApi();
    }, []);

    const getProfileApi = (res) => {
        dispatch(getProfileCallAPI(GET_PROFILE, {}, { run: true, navigate: navigate }));
    };

    const handleResend = () => {
        if (!ResendLoader) {
            dispatch(assessmentVerificationCallAPI(ASSESSMENT_VERIFICATION_EMAIL, {}, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate, handleToastError: (res) => ToastErrorFunction(res), toast: true }));
        }
    };

    const handleSuccess = (res) => {
        if (res?.data?.status_code === 200) {
            setConf({ msg: res?.data?.data, variant: 'success' });
            setStep(1);
        } else {
            setConf({ msg: res?.data?.data, variant: 'error' });
        }
    };

    const ToastErrorFunction = (res) => {
        setConf({ msg: intl.formatMessage({ id: "InvalidCredential!" }), variant: 'error' });
    };

    const handleStep = () => {
        if (profileData?.data?.is_active) {
            let body = {
                "language": profileData?.data?.subjects?.[0]?.language?.name,
                "type_of_board": profileData?.data?.subjects?.[0]?.teaching_material?.name,
                "subject": profileData?.data?.subjects?.[0]?.name,
                "grade": profileData?.data?.subjects?.[0]?.grade?.name?.split(" ")?.[0],
                "teacher_subject_id": String(profileData?.data?.subjects?.[0]?.id),
            }
            dispatch(getQuestionsCallAPI(GET_QUESTIONS, body, { run: true, handleFunction: (res) => handleRedirect(res), navigate: navigate, handleToastError: (res) => ToastErrorFunction(res), toast: true }));
        } else {
            setStep(2);
        }
    };

    const handleRedirect = () => {
        navigate(questions(locale));
    };

    // return (profileData?.data?.subjects?.length <= 1 ?
    //     <StartExamScreen onClick={() => handleStep()} />
    //     :
    //     <Assessment />);

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return <Assessment onClick={() => handleStep()} />;
            case 2:
                return <EmailVerification onClick={() => !ResendLoader && handleResend()} />;
            case 3:
                return <InterviewScreen />;
            default:
                return <Assessment />;
        }
    };

    return (
        <Container fluid className='dashboard_container'>
            <Row>
                <Col lg={2}>
                    {/* Empty space on the left */}
                </Col>
                <Col lg={9} className="d-flex align-items-center justify-content-center mt-lg-5 mb-lg-5">
                    {renderStepContent()}
                </Col>
                <Col lg={1}>
                    {/* Empty space on the right */}
                </Col>
            </Row>
        </Container>
    );
}

export default TeacherDashboard;