import React, { useEffect, useState, useRef, useContext } from "react";
import { Row, Col, Image, Stack, Badge } from "react-bootstrap";
import CameraIcon from "assets/images/cameraIconUpload.svg";
import LocationIcon from "assets/images/location_on.svg";
import callIcon from "assets/images/call.svg";
import mailIcon from "assets/images/mail.svg";
import StarIcon from "assets/images/star.svg";
import editIcon from "assets/images/edit.svg";
import addIcon from "assets/images/addIconColor.svg";
import profileAvtarIcon from "assets/images/ProfileAvtarIcon.svg";
import crossIcon from "assets/images/crossIcon.svg";
import deleteIcon from "assets/images/delete.svg";
import "components/feature/Teacher/Profile/Profile.scss";
import 'assets/styles/common.scss';
import ReusableTabs from "components/common/Tabs";
import CommonModal from "components/common/Modal";
import { Box } from "@mui/system";
import SelectInput from "components/common/Input/SelectInput";
import LabelWithInput from "components/common/Input/LabelWithInput";
import { cityDropDownCallAPI } from "store/Auth/Registration/City/action";
import { CITY_DROPDOWN } from "store/Auth/Registration/City/constant";
import { STATE_DROPDOWN } from "store/Auth/Registration/State/constant";
import { stateDropDownCallAPI } from "store/Auth/Registration/State/action";
import { COUNTRY_DROPDOWN } from "store/Auth/Registration/Country/constant";
import { countryDropDownCallAPI } from "store/Auth/Registration/Country/action";
import { useDispatch, useSelector } from "react-redux";
import CheckValidation from "components/feature/Teacher/PersonalDetails/validation";
import CheckBioValidation from "components/feature/Teacher/ProfileSetup/validation";
import CheckSubjectValidation from "components/feature/Teacher/TeacherDetails/validation";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CheckQualificationValidation from "components/feature/Teacher/Profile/validation";
import { getProfileCallAPI } from "store/Dashboard/Profile/action";
import { GET_PROFILE } from "store/Dashboard/Profile/constant";
import { editBioCallAPI } from "store/Dashboard/Bio/action";
import { EDIT_BIO } from "store/Dashboard/Bio/constant";
import { qualificationDropDownCallAPI } from "store/Auth/Registration/Qualification/action";
import { QUALIFICATION_DROPDOWN } from "store/Auth/Registration/Qualification/constant";
import { addQualificationCallAPI } from "store/Dashboard/Qualification/action";
import { ADD_QUALIFICATION } from "store/Dashboard/Qualification/constant";
import { editProfileCallAPI } from "store/Dashboard/EditProfile/action";
import { EDIT_PROFILE } from "store/Dashboard/EditProfile/constant";
import { teachingMaterialDropDownCallAPI } from "store/Auth/Registration/TeachingMaterial/action";
import { TEACHING_MATERIAL_DROPDOWN } from "store/Auth/Registration/TeachingMaterial/constant";
import { gradeLevelDropDownCallAPI } from "store/Auth/Registration/GreadeLevel/action";
import { GRADE_LEVEL_DROPDOWN } from "store/Auth/Registration/GreadeLevel/constant";
import { subjectDropDownCallAPI } from "store/Auth/Registration/Subject/action";
import { SUBJECT_DROPDOWN } from "store/Auth/Registration/Subject/constant";
import { languageDropDownCallAPI } from "store/Auth/Registration/Language/action";
import { LANGUAGE_DROPDOWN } from "store/Auth/Registration/Language/constant";
import InputAdornment from '@mui/material/InputAdornment';
import Search from "assets/images/Registration/search.png";
import { addTeacherSubjectCallAPI } from "store/Dashboard/AddTeacherSubject/action";
import { ADD_TEACHER_SUBJECT } from "store/Dashboard/AddTeacherSubject/constant";
import { editProfileImageCallAPI } from "store/Dashboard/EditProfileImage/action";
import { EDIT_PROFILE_IMAGE } from "store/Dashboard/EditProfileImage/constant";
import { FormattedMessage, useIntl } from "react-intl";
import moment from 'moment';
import { editQualificationCallAPI } from "store/Dashboard/EditQualification/action";
import { EDIT_QUALIFICATION } from "store/Dashboard/EditQualification/constant";
import ToastNotification from "components/common/Toast";
import MyCalendar from "components/feature/MyCalendar";
import { LocaleContext } from "routes/Router";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const ProfilePage = () => {

    const [tab, setTab] = useState(1);
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fileInputRef = useRef();
    const [isError, setIsError] = useState(false);
    const [isBioError, setIsBioError] = useState(false);
    const [isQualificationError, setIsQualificationError] = useState(false);
    const [isSubjectsError, setIsSubjectsError] = useState(false);
    const [isModalActive, setIsModalActive] = useState(false);
    const [isBioModalActive, setIsBioModalActive] = useState(false);
    const [isQualificationAddModalActive, setIsQualificationAddModalActive] = useState(false);
    const [isProfilePicModalActive, setIsProfilePicModalActive] = useState(false);
    const [qualificationEdit, setQualificationEdit] = useState(false);
    const [isSubjectAddModalActive, setIsSubjectAddModalActive] = useState(false);
    const [selectMaterial, setSelectMaterial] = useState("IG");
    const [materialId, setMaterialId] = useState(19);
    const listRef = useRef(null);
    const [isListVisible, setIsListVisible] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [files, setFiles] = useState(null);
    const [qualificationEditId, setQualificationEditId] = useState("");
    const [conf, setConf] = ToastNotification();
    const { locale } = useContext(LocaleContext);


    const [formQualificationValue, setformQualificationValue] = useState({
        specialisation: "",
        university: "",
        startYear: "",
        endYear: "",
    });

    const [formQualificationId, setformQualificationId] = useState({
        specialisation: "",
        university: "",
        startYear: "",
        endYear: "",
    });

    const [formId, setformId] = useState({
        fullName: "",
        country: "",
        state: "",
        city: "",
        email: "",
        phoneNumber: ""
    });

    const [formAddSubjectId, setFormAddSubjectId] = useState({
        gradeLevel: "",
        language: "",
        subject: ""
    });

    const [formValue, setformValue] = useState({
        fullName: "",
        country: "",
        state: "",
        city: "",
        email: "",
        phoneNumber: ""
    });

    const [formBioValue, setformBioValue] = useState({
        bio: "",
    });

    const [formAddSubjectValue, setFormAddSubjectValue] = useState({
        gradeLevel: "",
        language: "",
        experience: "",
        subject: ""
    });

    const countryList = useSelector(state => state?.countryDropDownReducer?.payload?.data);
    const stateList = useSelector(state => state?.stateDropDownReducer?.payload?.data);
    const cityList = useSelector(state => state?.cityDropDownReducer?.payload?.data);
    const TeachingMaterialList = useSelector(state => state?.teachingMaterialDropDownReducer?.payload?.data);
    const GradeLevelList = useSelector(state => state?.gradeLevelDropDownReducer?.payload?.data);
    const SubjectList = useSelector(state => state?.subjectDropDownReducer?.payload?.data?.data);
    const LanguageList = useSelector(state => state?.languageDropDownReducer?.payload?.data);
    const profileData = useSelector(state => state?.getProfileReducer?.payload?.data);
    const qualificationList = useSelector(state => state?.qualificationDropDownReducer?.payload?.data);

    const tabData = [
        { label: intl.formatMessage({ id: "profile.ProfileSummary" }) },
        { label: intl.formatMessage({ id: "profile.Qualifications" }) },
        { label: intl.formatMessage({ id: "profile.Reviews" }) },
        // { label: intl.formatMessage({ id: "profile.Availability" }) },
    ];

    if (profileData?.data?.is_internal !== 0) {
        tabData?.push({ label: intl.formatMessage({ id: "profile.Availability" }) });
    };

    useEffect(() => {
        getCountryListApi();
        getTeachingMaterialListApi();
        getGradeLevelListApi();
        getSubjectListApi();
        getLanguageListApi();
        getProfileApi();
        getQualificationListApi();
    }, []);

    const getProfileApi = (res) => {
        dispatch(getProfileCallAPI(GET_PROFILE, {}, { run: true, handleFunction: (res) => getProfileDetails(res), navigate: navigate }));
    };

    const getProfileDetails = (res) => {
        setFiles(res?.data?.data?.profile_image);
        setformValue((prev) => ({
            ...prev,
            ["fullName"]: res?.data?.data?.name,
            ["country"]: res?.data?.data?.country?.name,
            ["state"]: res?.data?.data?.state?.name,
            ["city"]: res?.data?.data?.city?.name,
            ["email"]: res?.data?.data?.email,
            ["phoneNumber"]: res?.data?.data?.phone

        }));

        setformId((prev) => ({
            ...prev,
            ['fullName']: res?.data?.data?.name,
            ['country']: res?.data?.data?.country?.id,
            ['state']: res?.data?.data?.state?.id,
            ['city']: res?.data?.data?.city?.id,
            ['email']: res?.data?.data?.email,
            ['phoneNumber']: res?.data?.data?.phone
        }));

        getStateList(res?.data?.data?.country?.id);
        getCityList(res?.data?.data?.state?.id);

        setformBioValue((prev) => ({
            ...prev,
            ["bio"]: res?.data?.data?.bio
        }));

        if (res?.data?.status_code === 200) {
            setConf({ msg: res?.data?.status, variant: 'success' });
        };
    };

    const getStateList = (id) => {
        let body = {
            "slug": "state",
            "parent_id": id
        };
        dispatch(stateDropDownCallAPI(STATE_DROPDOWN, body, { navigate: navigate }));
    };

    const getCityList = (id) => {
        let body = {
            "slug": "city",
            "parent_id": id
        };
        dispatch(cityDropDownCallAPI(CITY_DROPDOWN, body, { navigate: navigate }));
    };

    const getCountryListApi = () => {
        let body = {
            "slug": "country"
        };
        dispatch(countryDropDownCallAPI(COUNTRY_DROPDOWN, body, { navigate: navigate }));
    };

    const getTeachingMaterialListApi = () => {
        let body = {
            "slug": "teaching-material"
        };
        dispatch(teachingMaterialDropDownCallAPI(TEACHING_MATERIAL_DROPDOWN, body, { navigate: navigate }));
    };

    const getGradeLevelListApi = () => {
        let body = {
            "slug": "gradlevel"
        };
        dispatch(gradeLevelDropDownCallAPI(GRADE_LEVEL_DROPDOWN, body, { navigate: navigate }));
    };

    const getSubjectListApi = () => {
        let body = {
            "slug": "subject"
        };
        dispatch(subjectDropDownCallAPI(SUBJECT_DROPDOWN, body, { navigate: navigate }));
    };

    const getLanguageListApi = () => {
        dispatch(languageDropDownCallAPI(LANGUAGE_DROPDOWN, {}, { navigate: navigate }));
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit && isPhoneNumberValid) {
            let body = {
                "name": formValue.fullName,
                "phone": formValue.phoneNumber,
                "country_id": formId.country,
                "state_id": formId.state,
                "city_id": formId.city
            };
            dispatch(editProfileCallAPI(EDIT_PROFILE, body, { run: true, handleFunction: (res) => getProfileApi(res), navigate: navigate }));
            setIsModalActive(false);
        }
    };

    const handleEditBioSubmit = () => {
        const error = CheckBioValidation(formBioValue);
        setIsBioError(error);
        if (error.submit) {
            dispatch(editBioCallAPI(EDIT_BIO, formBioValue, { run: true, handleFunction: (res) => getProfileApi(res), navigate: navigate }));
            setIsBioModalActive(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
        setformId((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleBioChange = (e) => {
        const { name, value } = e.target;
        setformBioValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "country") {
            let body = {
                "slug": "state",
                "parent_id": value?.id
            };
            dispatch(stateDropDownCallAPI(STATE_DROPDOWN, body, { navigate: navigate }));
            setformValue((prev) => ({
                ...prev,
                ["state"]: "",
                ['city']: ""
            }));
        }
        if (name === "state") {
            let body = {
                "slug": "city",
                "parent_id": value?.id
            };
            dispatch(cityDropDownCallAPI(CITY_DROPDOWN, body, { navigate: navigate }));
            setformValue((prev) => ({
                ...prev,
                ['city']: ""
            }));
        }

        setformValue((prev) => ({
            ...prev,
            [name]: value?.name
        }));

        setformId((prev) => ({
            ...prev,
            [name]: value?.id
        }))
    };

    const handleAddQualificationSubmit = () => {
        const error = CheckQualificationValidation(formQualificationValue);
        setIsQualificationError(error);
        if (error.submit) {
            let body = {
                "value_id": formQualificationId.specialisation,
                "university_name": formQualificationValue.university,
                "start_year": formQualificationValue.startYear,
                "end_year": formQualificationValue.endYear
            };
            dispatch(addQualificationCallAPI(ADD_QUALIFICATION, body, { run: true, handleFunction: (res) => getProfileApi(res), navigate: navigate }));
            setIsQualificationAddModalActive(false);
        }
    };

    const handleEditQualificationSubmit = (id) => {
        const error = CheckQualificationValidation(formQualificationValue);
        setIsQualificationError(error);
        if (error.submit) {
            let body = {
                "value_id": formQualificationId.specialisation,
                "university_name": formQualificationValue.university,
                "start_year": formQualificationValue.startYear,
                "end_year": formQualificationValue.endYear
            };
            dispatch(editQualificationCallAPI(EDIT_QUALIFICATION, body, { id: id, run: true, handleFunction: (res) => getProfileApi(res), navigate: navigate }));
            setIsQualificationAddModalActive(false);
        }
    };

    const handleQualificationChange = (e) => {
        const { name, value } = e.target;
        setformQualificationValue((prev) => ({
            ...prev,
            [name]: name === "specialisation" && value?.name ? value.name : value
        }))
        setformQualificationId((prev) => ({
            ...prev,
            [name]: name === "specialisation" && value?.id ? value.id : value
        }))
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        if (file) {
            let Data = new FormData();
            Data.append("profile_image", file);
            dispatch(editProfileImageCallAPI(EDIT_PROFILE_IMAGE, Data, { run: true, handleFunction: (res) => getProfileApi(res), navigate: navigate, toast: true }));
            setIsProfilePicModalActive(false);
        }
    };

    const handleFileDelete = () => {
        setFiles(null);
        setIsProfilePicModalActive(false);
    };

    const handleUpload = () => {
        fileInputRef.current.click();
    };

    const getQualificationListApi = () => {
        let body = {
            "slug": "qualification"
        };
        dispatch(qualificationDropDownCallAPI(QUALIFICATION_DROPDOWN, body, { navigate: navigate }));
    };

    const handleSelect = (data) => {
        setSelectMaterial(data?.name);
        setMaterialId(data?.id);
    };

    const handleAddSubjectChange = (e) => {
        const { name, value } = e?.target;
        setFormAddSubjectValue((prev) => ({
            ...prev,
            [name]: value
        }))
        if (name === "subject") {
            const filteredOptions = SubjectList && SubjectList?.length > 0 && SubjectList?.filter(option =>
                option?.name?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredOptions(filteredOptions);
        }
    };

    const handleAddSubjectInputChange = (e) => {
        const { name, value } = e?.target;
        setFormAddSubjectValue((prev) => ({
            ...prev,
            [name]: value?.name
        }));
        setFormAddSubjectId((prev) => ({
            ...prev,
            [name]: value?.id
        }));
    };

    const handleItemClick = (item) => {
        setFormAddSubjectValue((prev) => ({
            ...prev,
            ['subject']: item?.name
        }));
        setFormAddSubjectId((prev) => ({
            ...prev,
            ['subject']: item?.id
        }));
        setIsListVisible(false);
    };

    const handleAddSubjectSubmit = () => {
        const error = CheckSubjectValidation(formAddSubjectValue);
        setIsSubjectsError(error);
        if (error.submit) {
            let body = {
                "subject_id": formAddSubjectId?.subject,
                "grade_id": formAddSubjectId?.gradeLevel,
                "teaching_material_id": materialId,
                "language_id": formAddSubjectId?.language,
                "experience": formAddSubjectValue?.experience,
                "status": 69
            }
            dispatch(addTeacherSubjectCallAPI(ADD_TEACHER_SUBJECT, body, { run: true, handleFunction: (res) => getProfileApi(res), navigate: navigate, toast: true, handleToastError: (res) => handleError(res) }));
            setIsSubjectAddModalActive(false);
        }
    };

    const handleError = (res) => {
        setConf({ msg: res?.data?.message, variant: 'error' });
    };

    const handleEditQualification = (item) => {

        setIsQualificationAddModalActive(true);
        setQualificationEdit(true);
        setQualificationEditId(item?.id);
        setformQualificationValue((prev) => ({
            ...prev,
            ["specialisation"]: item?.name,
            ["university"]: item?.university_name,
            ["startYear"]: item?.start_year,
            ["endYear"]: item?.end_year,
        }));
        setformQualificationId((prev) => ({
            ...prev,
            ["specialisation"]: item?.value_id,
            ["university"]: item?.university_name,
            ["startYear"]: item?.start_year,
            ["endYear"]: item?.end_year,
        }));
    };

    const handleEditProfile = () => {
        setIsError(false);
        setIsModalActive(true);
        setformValue((prev) => ({
            ...prev,
            ["fullName"]: profileData?.data?.name,
            ["country"]: profileData?.data?.country?.name,
            ["state"]: profileData?.data?.state?.name,
            ["city"]: profileData?.data?.city?.name,
            ["email"]: profileData?.data?.email,
            ["phoneNumber"]: profileData?.data?.phone
        }));
        setCountryCode(`+${profileData?.data?.country?.slug === 'qatar' ? '974' : ''}`);
    };

    const handleEditBio = () => {
        setIsBioError(false);
        setIsBioModalActive(true);
        setformBioValue((prev) => ({
            ...prev,
            ["bio"]: profileData?.data?.bio
        }));
    };

    const handleAddQualification = () => {
        setIsQualificationError(false);
        setIsQualificationAddModalActive(true);
        setQualificationEdit(false);
        setformQualificationValue((prev) => ({
            ...prev,
            ["specialisation"]: "",
            ["university"]: "",
            ["startYear"]: "",
            ["endYear"]: "",
        }));
    };

    const handleAddSubjects = () => {
        setIsSubjectsError(false);
        setIsSubjectAddModalActive(true);
        setFormAddSubjectValue((prev) => ({
            ...prev,
            ["gradeLevel"]: "",
            ["language"]: "",
            ["experience"]: "",
            ["subject"]: "",
        }));
        setSelectMaterial('IG');
    };

    const handleClose = () => {
        setIsModalActive(false);
    };

    const handleCloseBioModel = () => {
        setIsBioModalActive(false);
    };

    const handleCloseQualificationModel = () => {
        setIsQualificationAddModalActive(false);
    };

    const handleCloseSubjectModel = () => {
        setIsSubjectAddModalActive(false);
    };


    ////////////////////////////////countryCode///////////////////////////////

    const [countryCode, setCountryCode] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const phoneNumberInputRef = useRef(null);

    const handleCountryCodeChange = (value, country) => {
        setCountryCode(`+${country.dialCode}`);
        phoneNumberInputRef.current.focus();
    };

    const handlePhoneNumberChange = (e) => {
        const phoneNumberValue = e.target.value;
        setformValue((prev) => ({
            ...prev,
            [e.target.name]: phoneNumberValue
        }));

        setformId((prev) => ({
            ...prev,
            [e.target.name]: phoneNumberValue
        }));

        const fullPhoneNumber = `${countryCode}${phoneNumberValue}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);

        if (parsedPhoneNumber) {
            setIsPhoneNumberValid(parsedPhoneNumber.isValid());
        } else {
            setIsPhoneNumberValid(false);
        }
    };
    ////////////////////////////////countryCode///////////////////////////////

    return (
        <Row className="mt-5 mt-lg-0 m-0">
            <Row className="page-content m-0 mt-3 mt-lg-0">
                <Col md={12} lg={12} xl={12} xxl={5} className="flex flex-col justify-end">
                    <Stack direction="horizontal" gap={3} className="flex flex-col align-items-start justify-between">
                        <div className="flex justify-end">
                            <div className="profilePic">
                                <Image src={files ? files : profileAvtarIcon} alt={"icon"} className="userPic" />
                                <div className="cameraIcon">
                                    <Image src={CameraIcon} alt={"icon"} style={{ cursor: "pointer", height: "32px", width: "32px" }} onClick={() => setIsProfilePicModalActive(true)} />
                                </div>
                            </div>

                            <div className="ms-3">
                                <p className="profileName_Heading">{profileData?.data?.name}</p>
                                <Stack direction="horizontal" gap={2} className="">
                                    <Image src={StarIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                    <p className='mb-0 review' >{profileData?.data?.review?.length > 0 ? profileData?.payload?.data?.data?.review?.length : <FormattedMessage id="profile.NoReview" />}</p>
                                </Stack>
                                <Stack direction="horizontal" gap={2} className="mt-2">
                                    <Image src={LocationIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                                    <p className='mb-0 location' >{profileData?.data?.city?.name}</p>
                                </Stack>
                            </div>
                        </div>

                        <div className="d-flex d-xxl-none">
                            <Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} onClick={() => handleEditProfile()} />
                        </div>
                    </Stack>
                </Col>

                <Col md={6} lg={6} xl={6} xxl={4} className="mx-auto flex flex-col justify-end text-center mt-2 xxl:mt-0">
                    <Stack direction="horizontal" gap={2} className="justify-start">
                        <Image src={mailIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                        <p className='mb-0 ProfileKey' ><FormattedMessage id="profile.EmailId:" /></p>
                        <p className='mb-0 ProfileValue' >{profileData?.data?.email}</p>
                    </Stack>
                    <Stack direction="horizontal" gap={2} className="mt-2 justify-start">
                        <Image src={callIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} />
                        <p className='mb-0 ProfileKey' ><FormattedMessage id="profile.PhoneNo:" /></p>
                        <p className='mb-0 ProfileValue' >{profileData?.data?.phone}</p>
                    </Stack>
                </Col>

                <Col md={6} lg={6} xl={6} xxl={3} className="flex flex-col justify-between mt-2 xxl:mt-0">
                    <Stack direction="horizontal" gap={2} className="d-none justify-end d-xxl-flex" onClick={() => handleEditProfile()}>
                        <Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} />
                        <p className='mb-0 buttonSelector' style={{ color: "#911E59", cursor: "pointer", }}><FormattedMessage id="EditProfile" /></p>
                    </Stack>
                    <Stack direction="horizontal" gap={2} className="flex justify-start md:justify-center xxl:justify-end">
                        <p className='mb-0 ProfileKey' ><FormattedMessage id="profile.ProfileLastUpdated:" /></p>
                        <p className='mb-0 ProfileValue' >{moment(profileData?.data?.last_updated).format("YYYY-MM-DD")}</p>
                    </Stack>
                </Col>
            </Row>

            <Row className="mt-3 p-0 m-0">
                <Col lg={10} xl={9} xxl={7} className="m-0 p-0">
                    <ReusableTabs tabs={tabData} defaultTab={1} currentTab={(val) => setTab(val)} />
                </Col>
            </Row>

            {tab === 1 &&
                <>
                    <Row className="page-content mt-3 m-0">
                        <div className="flex justify-between">
                            <p className="buttonSelector">
                                <FormattedMessage id="Bio" />
                            </p>
                            <Stack direction="horizontal" gap={2} className="flex justify-end" onClick={() => handleEditBio()}>
                                <Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} />
                                <p className='mb-0 buttonSelector' style={{ color: "#911E59", cursor: "pointer", }}><FormattedMessage id="EditBio" /></p>
                            </Stack>
                        </div>
                        <div className="mt-2">
                            <p className={profileData?.data?.bio != null ? "bioDetails" : " bioDetails flex justify-center"}>
                                {profileData?.data?.bio ?? <FormattedMessage id="NoData" />}
                            </p>
                        </div>
                    </Row>

                    <Row className="page-content mt-3 m-0 mb-3 mb-lg-0">
                        <div className="flex justify-between">
                            <p className="mb-0 buttonSelector">
                                <FormattedMessage id="Qualifications" />
                            </p>
                            <Stack direction="horizontal" gap={2} className="flex justify-end" onClick={() => handleAddQualification()}>
                                <Image src={addIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} />
                                <p className='mb-0 buttonSelector' style={{ color: "#911E59", cursor: "pointer" }}><FormattedMessage id="AddQualification" /></p>
                            </Stack>
                        </div>

                        {profileData?.data?.qualificaitons?.length > 0 && profileData?.data?.qualificaitons.map((item, index) => {
                            return (
                                <div className="mt-4">
                                    <Stack direction="horizontal" gap={2}>
                                        <p className='mb-1 buttonSelector' style={{ color: "#911E59" }}>{item?.name}</p>
                                        <Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} onClick={() => handleEditQualification(item)} />
                                    </Stack>
                                    <p className="mb-1 qualificationDetails">{item?.university_name}</p>
                                    {item?.start_year != null && <p className="mb-1 qualificationDetails" style={{ color: "#A2A2A2" }}>{item?.start_year}-{item?.end_year}</p>}
                                </div>
                            )
                        })}
                    </Row>
                </>
            }

            {tab === 2 &&
                <>
                    <Row className="page-content mt-3 m-0 mb-3 mb-lg-0">
                        <div className="flex justify-between">
                            <p className="subjectTitle m-0 mb-2">
                                <FormattedMessage id="profile.Subjects" />
                            </p>
                            <Stack direction="horizontal" gap={2} className="flex justify-end" onClick={() => handleAddSubjects()} >
                                <Image src={addIcon} alt={"icon"} style={{ cursor: "pointer", height: '24px', width: '24px' }} />
                                <p className='mb-0 buttonSelector' style={{ color: "#911E59", cursor: "pointer", }}><FormattedMessage id="profile.AddSubject" /></p>
                            </Stack>
                        </div>
                        {profileData?.data?.subjects?.length > 0 && profileData?.data?.subjects.map((result, index) => (
                            <div className="boxBorder mt-2">
                                <div className="flex align-items-center">
                                    <p className={`subjectName mb-0 ${locale === 'en' ? 'mr-3' : 'ml-3'}`}>{result?.name}</p>
                                    <Badge pill className={result?.status?.slug === "failed" ? 'failedPill' : result?.status?.slug === "verified" ? "verifiedPill" : 'pendingPill'}>
                                        {["failed", "pending", "evaluating", "verified"].includes(result?.status?.slug) ? result?.status?.name : <FormattedMessage id="SlotBooked" />}
                                    </Badge>
                                </div>
                                <div className="flex align-items-center mt-3 subjectKey flex-wrap">
                                    <p className="mr-5"><FormattedMessage id="Grade" />: <span className="subjectValue">{result?.grade?.name}</span></p>
                                    <p className="mr-5"><FormattedMessage id="Material" />: <span className="subjectValue">{result?.teaching_material?.name}</span></p>
                                    <p className="mr-5"><FormattedMessage id="Language" />: <span className="subjectValue">{result?.language?.name}</span></p>
                                    <p className="mr-5"><FormattedMessage id="Experience" />: <span className="subjectValue">{result?.experience} {'year'}</span></p>
                                </div>
                            </div>
                        ))}
                    </Row>
                </>
            }

            {tab === 3 &&
                <>
                    <Row className="page-content mt-3 m-0 mb-3 mb-lg-0">
                        <div className="mt-2 text-center">
                            <p>
                                <FormattedMessage id="profile.NoReview" />
                            </p>
                        </div>
                    </Row>
                </>
            }

            {tab === 4 && profileData?.data?.is_internal !== 0 &&
                <MyCalendar />
            }

            {/* Edit Profile modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isModalActive} handleClose={handleClose}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id="EditProfile" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={handleClose} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-4'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.FullName" />}
                                    placeholder={intl.formatMessage({ id: "register.FullName" })}
                                    name="fullName"
                                    type="text"
                                    value={formValue.fullName}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.fullName.error && isError.fullName.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <SelectInput
                                    label={<FormattedMessage id="register.Country" />}
                                    placeholder={intl.formatMessage({ id: "register.Country" })}
                                    name={"country"}
                                    value={formValue?.country}
                                    data={countryList?.data}
                                    onChange={(e) => handleInputChange(e)}
                                    errorText={isError && isError.country.error && isError.country.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <SelectInput
                                    label={<FormattedMessage id="register.State" />}
                                    placeholder={intl.formatMessage({ id: "register.State" })}
                                    name={"state"}
                                    value={formValue?.state}
                                    data={stateList?.data}
                                    onChange={(e) => handleInputChange(e)}
                                    errorText={isError && isError.state.error && isError.state.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <SelectInput
                                    label={<FormattedMessage id="register.City" />}
                                    placeholder={intl.formatMessage({ id: "register.City" })}
                                    name={"city"}
                                    value={formValue?.city}
                                    data={cityList?.data}
                                    onChange={(e) => handleInputChange(e)}
                                    errorText={isError && isError.city.error && isError.city.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.EmailId" />}
                                    placeholder={intl.formatMessage({ id: "register.EmailId" })}
                                    name="email"
                                    type="text"
                                    value={formValue?.email}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.email.error && isError.email.errorMessage}
                                    disabled
                                />
                            </Col>

                            <Col xs={12} md={12} className='mt-3'>
                                <div className="phone-number-container">
                                    <label className="phone-label">Phone Number</label>
                                    <div className="phone-input-wrapper">

                                        <div className="country-code-input">
                                            <PhoneInput
                                                country={'qa'}
                                                value={countryCode}
                                                onChange={handleCountryCodeChange}
                                                inputProps={{
                                                    name: 'countryCode',
                                                    required: true,
                                                }}
                                                placeholder="country"
                                                inputClass="custom-country-input"
                                                disableCountryCode={true}
                                                disableDropdown={false}
                                                onlyCountries={['qa', 'us', 'in', 'gb']}
                                            />
                                        </div>

                                        <div className="phone-number-input">
                                            <input
                                                type="number"
                                                name="phoneNumber"
                                                value={formValue?.phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                                ref={phoneNumberInputRef}
                                                placeholder="e.g: 8521364783"
                                                className={`custom-phone-input ${!isPhoneNumberValid ? 'invalid' : ''}`}
                                            />
                                        </div>
                                    </div>

                                    {isError && isError.phoneNumber.error && isError.phoneNumber.errorMessage ?
                                        <p className="error-message">{isError.phoneNumber.errorMessage}</p>
                                        :
                                        !isPhoneNumberValid ?
                                            <p className="error-message">Invalid phone number for selected country code</p>
                                            :
                                            ""
                                    }

                                </div>
                            </Col>

                        </Row>

                        <Row >
                            <Button variant="contained" onClick={() => handleSubmit()} className='button mt-5 md:mt-2'><FormattedMessage id="Save" /></Button>
                        </Row>
                    </Box>
                }
            />
            {/* Edit Profile modal  */}

            {/* Edit Bio modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isBioModalActive} handleClose={() => handleCloseBioModel()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id="Bio" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseBioModel()} />
                                </div>
                            </Col>

                            <Col xs={12} md={12}>
                                <LabelWithInput
                                    placeholder={intl.formatMessage({ id: "profile.BioTextSomething" })}
                                    marginTop="0"
                                    height="250"
                                    multiline
                                    rows={11}
                                    name="bio"
                                    value={formBioValue?.bio}
                                    onChange={(e) => handleBioChange(e)}
                                    errorText={isBioError && isBioError.bio.error && isBioError.bio.errorMessage}
                                />
                            </Col>

                        </Row>

                        <Row >
                            <Button variant="contained" onClick={() => handleEditBioSubmit()} className='button mt-5 md:mt-2'><FormattedMessage id="Save" /></Button>
                        </Row>
                    </Box>
                }
            />
            {/* Edit Bio modal  */}


            {/* Add Qualification Modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isQualificationAddModalActive} handleClose={() => handleCloseQualificationModel()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2">{qualificationEdit ? <FormattedMessage id="EditQualification" /> : <FormattedMessage id="AddQualification" />}</h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseQualificationModel()} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-4'>
                                <SelectInput
                                    label={<FormattedMessage id="profile.Specialisation" />}
                                    placeholder={intl.formatMessage({ id: "profile.Specialisation" })}
                                    name={"specialisation"}
                                    value={formQualificationValue?.specialisation}
                                    data={qualificationList?.data}
                                    onChange={(e) => handleQualificationChange(e)}
                                    errorText={isQualificationError && isQualificationError.specialisation.error && isQualificationError.specialisation.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={12} className='mt-3'>
                                <LabelWithInput
                                    label={<FormattedMessage id="profile.University" />}
                                    placeholder={intl.formatMessage({ id: "profile.University" })}
                                    name="university"
                                    type="text"
                                    value={formQualificationValue?.university}
                                    onChange={(e) => handleQualificationChange(e)}
                                    errorText={isQualificationError && isQualificationError.university.error && isQualificationError.university.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <LabelWithInput
                                    label={<FormattedMessage id="profile.StartingYear" />}
                                    placeholder={intl.formatMessage({ id: "profile.StartingYear" })}
                                    name="startYear"
                                    type="number"
                                    value={formQualificationValue?.startYear}
                                    onChange={(e) => handleQualificationChange(e)}
                                    errorText={isQualificationError && isQualificationError.startYear.error && isQualificationError.startYear.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <LabelWithInput
                                    label={<FormattedMessage id="profile.EndingYear" />}
                                    placeholder={intl.formatMessage({ id: "profile.EndingYear" })}
                                    name="endYear"
                                    type="number"
                                    value={formQualificationValue?.endYear}
                                    onChange={(e) => handleQualificationChange(e)}
                                    errorText={isQualificationError && isQualificationError.endYear.error && isQualificationError.endYear.errorMessage}
                                />
                            </Col>
                        </Row>

                        <Row >
                            <Button variant="contained" onClick={() => qualificationEdit ? handleEditQualificationSubmit(qualificationEditId) : handleAddQualificationSubmit()} className='button mt-5 md:mt-2'><FormattedMessage id="Save" /></Button>
                        </Row>
                    </Box>
                }
            />
            {/* Add Qualification Modal  */}

            {/* Profile Picture Modal  */}
            <CommonModal padding={'32px 48px'} width={'450px'} modalActive={isProfilePicModalActive} handleClose={() => setIsProfilePicModalActive(false)}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className='heading2'> <FormattedMessage id="profile.ProfileImage" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => setIsProfilePicModalActive(false)} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-2 flex justify-center'>
                                <Image src={files ? files : profileAvtarIcon} alt={"icon"} style={{ height: '200px', width: '200px', borderRadius: '50%' }} />
                            </Col>

                            <input
                                ref={fileInputRef}
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                disabled={false}
                                style={{ display: 'none' }} // Hide the file input
                                onChange={handleFileChange}
                            />

                            <Col xs={12} md={12} className="mt-5">
                                <div className="flex justify-between">
                                    <Button variant="outlined"
                                        className="profileButton"
                                        startIcon={<Image src={editIcon} alt={"icon"} style={{ cursor: "pointer", height: '20px', width: '20px' }} />}
                                        sx={{
                                            borderColor: '#911E59',
                                            color: '#911E59',
                                            '&:hover': {
                                                borderColor: '#911E59',
                                                backgroundColor: '#FFF2F8',
                                                color: '#911E59',
                                            },
                                        }}
                                        onClick={handleUpload}
                                    >
                                        <FormattedMessage id="profile.ChangeImage" />
                                    </Button>
                                    <Button variant="outlined"
                                        className="profileButton"
                                        startIcon={<Image src={deleteIcon} alt={"icon"} style={{ cursor: "pointer", height: '20px', width: '20px' }} />}
                                        sx={{
                                            borderColor: '#911E59',
                                            color: '#911E59',
                                            '&:hover': {
                                                borderColor: '#911E59',
                                                backgroundColor: '#FFF2F8',
                                                color: '#911E59',
                                            },
                                        }}
                                        onClick={handleFileDelete}
                                    >
                                        <FormattedMessage id="profile.RemoveImage" />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Box>
                }
            />
            {/* Profile Picture Modal  */}

            {/* Add Subject Modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isSubjectAddModalActive} handleClose={() => handleCloseSubjectModel()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id="profile.AddSubject" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseSubjectModel()} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-4'>
                                <p className='mb-2 teacher_label'><FormattedMessage id="register.TeachingMaterial" /></p>
                                <div className="flex flex-row items-center flex-wrap gap-3 teachingMaterial">
                                    {TeachingMaterialList && TeachingMaterialList?.data?.length > 0 && TeachingMaterialList.data.map((k, i) => (
                                        <p className={selectMaterial === k?.name ? 'selectTeachingMaterial' : 'unSelectTeachingMaterial'} onClick={() => handleSelect(k)}>{k?.name}</p>
                                    ))}
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-2'>
                                <LabelWithInput
                                    ref={listRef}
                                    label={<FormattedMessage id="register.SelectASubject" />}
                                    placeholder={intl.formatMessage({ id: "register.SelectASubject" })}
                                    name="subject"
                                    type="text"
                                    value={formAddSubjectValue?.subject}
                                    onChange={(e) => handleAddSubjectChange(e)}
                                    onClick={() => setIsListVisible(true)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={Search} alt={"icon"} />
                                            </InputAdornment>
                                        )
                                    }}
                                    errorText={isSubjectsError && isSubjectsError?.subject?.error && isSubjectsError?.subject?.errorMessage}
                                />
                                {isListVisible && (
                                    <ul ref={listRef} style={{ width: "37%" }} className="custom-list">
                                        {filteredOptions?.length > 0 && filteredOptions?.map((k) => (
                                            <li className="list-item" onClick={() => handleItemClick(k)}>{k?.name}</li>
                                        ))}
                                    </ul>
                                )}

                            </Col>

                            <Col xs={12} md={12} className='mt-2'>
                                <SelectInput
                                    label={<FormattedMessage id="register.GradeLevel" />}
                                    placeholder={intl.formatMessage({ id: "register.GradeLevel" })}
                                    name={"gradeLevel"}
                                    value={formAddSubjectValue?.gradeLevel}
                                    data={GradeLevelList?.data}
                                    onChange={(e) => handleAddSubjectInputChange(e)}
                                    errorText={isSubjectsError && isSubjectsError?.gradeLevel?.error && isSubjectsError?.gradeLevel?.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-2'>
                                <SelectInput
                                    label={<FormattedMessage id="register.Language" />}
                                    placeholder={intl.formatMessage({ id: "register.Language" })}
                                    name={"language"}
                                    value={formAddSubjectValue?.language}
                                    data={LanguageList?.data}
                                    onChange={(e) => handleAddSubjectInputChange(e)}
                                    errorText={isSubjectsError && isSubjectsError?.language?.error && isSubjectsError?.language?.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-2'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.TeachingExperience" />}
                                    placeholder={intl.formatMessage({ id: "register.TeachingExperience" })}
                                    name="experience"
                                    type="number"
                                    value={formAddSubjectValue?.experience}
                                    onChange={(e) => handleAddSubjectChange(e)}
                                    errorText={isSubjectsError && isSubjectsError?.experience?.error && isSubjectsError?.experience?.errorMessage}
                                />
                            </Col>
                        </Row>

                        <Row >
                            <Button variant="contained" onClick={() => handleAddSubjectSubmit()} className='button mt-5 md:mt-2'><FormattedMessage id="Save" /></Button>
                        </Row>
                    </Box>
                }
            />
            {/* Add Subject Modal  */}

        </Row>
    );
};

export default ProfilePage;
