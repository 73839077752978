import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import "assets/styles/common.scss";

const CustomPopover = ({ id, open, anchorEl, handleClose, options }) => {
  return (
    <Popover
      className="Popover"
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {options.map((option, index) => (
        <Typography
          key={index}
          sx={{ pt: 1, pl: 2, pr: 2, pb: index === options.length - 1 ? 2 : 1 }}
          className="group"
          onClick={option.onClick}
          style={{ cursor: 'pointer' }}
        >
          <div className="flex items-end group">
            {option.icon}
            <p className="m-0 group">{option.label}</p>
          </div>
        </Typography>
      ))}
    </Popover>
  );
};

export default CustomPopover;
