import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Image } from "react-bootstrap";
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/Admin/TeacherDetails/teacherDetails.scss";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SwitchInput from "components/common/Input/SwitchInput";
import ReusableTabs from "components/common/Tabs";
import { GET_ALL_TEACHERS_LIST } from "store/AdminDashboard/GetAllTeacherLists/constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllTeachersListCallAPI } from "store/AdminDashboard/GetAllTeacherLists/action";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CustomPopover from "components/common/CutomPopover";
import { updateUserStatusCallAPI } from "store/AdminDashboard/UpdateUserStatus/action";
import { UPDATE_USER_STATUS } from "store/AdminDashboard/UpdateUserStatus/constant";
import ToastNotification from "components/common/Toast";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CommonModal from "components/common/Modal";
import LabelWithInput from "components/common/Input/LabelWithInput";
import SelectInput from "components/common/Input/SelectInput";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import crossIcon from "assets/images/crossIcon.svg";
import { stateDropDownCallAPI } from "store/Auth/Registration/State/action";
import { STATE_DROPDOWN } from "store/Auth/Registration/State/constant";
import { cityDropDownCallAPI } from "store/Auth/Registration/City/action";
import { CITY_DROPDOWN } from "store/Auth/Registration/City/constant";
import { countryDropDownCallAPI } from "store/Auth/Registration/Country/action";
import { COUNTRY_DROPDOWN } from "store/Auth/Registration/Country/constant";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CheckValidation from "components/feature/Teacher/PersonalDetails/validation";
import { UPDATE_USER_DATA } from "store/AdminDashboard/UpdateUserData/constant";
import { updateUserDataCallAPI } from "store/AdminDashboard/UpdateUserData/action";

const TeacherDetails = () => {

    const [isChecked, setIsChecked] = useState(false);
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [conf, setConf] = ToastNotification();
    const [anchorEl, setAnchorEl] = useState(null);
    const [tab, setTab] = useState(1);

    const AllTeacherList = useSelector(state => state?.getAllTeachersListReducer?.payload?.data);
    const countryList = useSelector(state => state?.countryDropDownReducer?.payload?.data);
    const stateList = useSelector(state => state?.stateDropDownReducer?.payload?.data);
    const cityList = useSelector(state => state?.cityDropDownReducer?.payload?.data);

    const [page, setPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page
    const [isError, setIsError] = useState(false);
    const [isModalActive, setIsModalActive] = useState(false);
    const [viewData, setViewData] = useState("");

    const [formValue, setformValue] = useState({
        fullName: "",
        country: "",
        state: "",
        city: "",
        email: "",
        phoneNumber: ""
    });

    const [formId, setformId] = useState({
        fullName: "",
        country: "",
        state: "",
        city: "",
        email: "",
        phoneNumber: ""
    });

    const handlePopoverClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setViewData(item);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const PopoverData = [
        {
            label: <FormattedMessage id="View" />,
            icon: <VisibilityOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => console.log('View clicked'),
        },
        {
            label: <FormattedMessage id="Edit" />,
            icon: <EditOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => handleEditModel(),
        },
        {
            label: <FormattedMessage id="Delete" />,
            icon: <DeleteOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />,
            onClick: () => console.log('Delete clicked'),
        },
    ];

    useEffect(() => {
        getAllTeacherLists(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const getAllTeacherLists = (page, rowsPerPage) => {
        let params = { page, limit: rowsPerPage };
        dispatch(getAllTeachersListCallAPI(GET_ALL_TEACHERS_LIST, { params }, { run: true, navigate: navigate }));
    };

    const tabData = [
        { label: intl.formatMessage({ id: "Recently Enrolled" }) },
        { label: intl.formatMessage({ id: "Cleared AI test" }) },
        { label: intl.formatMessage({ id: "Verified" }) },
    ];

    const handleSwitchChange = (event, item) => {
        let body = {
            "user_status": !item?.userstatus
        };
        dispatch(updateUserStatusCallAPI(UPDATE_USER_STATUS, body, { statusId: item?.id, run: true, navigate: navigate, handleFunction: (res) => handleSuccess(res) }));
    };

    const handleSuccess = (res) => {
        setConf({ msg: res?.data?.data, variant: 'success' });
        getAllTeacherLists(page, rowsPerPage);
    };


    ////////// Pagination ////////////////////

    const totalItems = AllTeacherList?.meta?.total || 0;
    const totalPages = Math.ceil(totalItems / rowsPerPage);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    ////////// Pagination ////////////////////

    ///////////Edit User/////////////////////

    useEffect(() => {
        getCountryListApi();
    }, []);

    const handleClose = () => {
        setIsModalActive(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
        setformId((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleEditModel = () => {
        // setIsModalActive(true);
        setformValue((prev) => ({
            ...prev,
            ["fullName"]: viewData.name,
            ["country"]: viewData?.country,
            ["state"]: viewData?.state,
            ["city"]: viewData?.city,
            ["email"]: viewData?.email,
            ["phoneNumber"]: viewData?.phone

        }));
    };

    const getCountryListApi = () => {
        let body = {
            "slug": "country"
        };
        dispatch(countryDropDownCallAPI(COUNTRY_DROPDOWN, body, { navigate: navigate }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "country") {
            let body = {
                "slug": "state",
                "parent_id": value?.id
            };
            dispatch(stateDropDownCallAPI(STATE_DROPDOWN, body, { navigate: navigate }));
            setformValue((prev) => ({
                ...prev,
                ["state"]: "",
                ['city']: ""
            }));
        }
        if (name === "state") {
            let body = {
                "slug": "city",
                "parent_id": value?.id
            };
            dispatch(cityDropDownCallAPI(CITY_DROPDOWN, body, { navigate: navigate }));
            setformValue((prev) => ({
                ...prev,
                ['city']: ""
            }));
        }

        setformValue((prev) => ({
            ...prev,
            [name]: value?.name
        }));

        setformId((prev) => ({
            ...prev,
            [name]: value?.id
        }))
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit && isPhoneNumberValid) {
            let body = {
                "name": formValue.fullName,
                "phone": formValue.phoneNumber,
                "country_id": formId.country,
                "state_id": formId.state,
                "city_id": formId.city
            };
            dispatch(updateUserDataCallAPI(UPDATE_USER_DATA, body, { statusId: viewData?.id, run: true, handleFunction: () => getAllTeacherLists(page, rowsPerPage), navigate: navigate }));
            setIsModalActive(false);
        }
    };

    ////////////////////////////////countryCode///////////////////////////////

    const [countryCode, setCountryCode] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const phoneNumberInputRef = useRef(null);

    const handleCountryCodeChange = (value, country) => {
        setCountryCode(`+${country.dialCode}`);
        phoneNumberInputRef.current.focus();
    };

    const handlePhoneNumberChange = (e) => {
        const phoneNumberValue = e.target.value;
        setformValue((prev) => ({
            ...prev,
            [e.target.name]: phoneNumberValue
        }));

        setformId((prev) => ({
            ...prev,
            [e.target.name]: phoneNumberValue
        }));

        const fullPhoneNumber = `${countryCode}${phoneNumberValue}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);

        if (parsedPhoneNumber) {
            setIsPhoneNumberValid(parsedPhoneNumber.isValid());
        } else {
            setIsPhoneNumberValid(false);
        }
    };
    ////////////////////////////////countryCode///////////////////////////////

    ///////////Edit User/////////////////////

    return (
        <Row className="mt-5 mt-lg-0 pb-0 m-0">
            <h5 className="heading5 mt-2 mt-lg-0 p-0"><FormattedMessage id="Teachers" /></h5>

            <Row className="mt-1 p-0 m-0">
                <Col lg={10} xl={9} xxl={7} className="m-0 p-0">
                    <ReusableTabs tabs={tabData} defaultTab={1} currentTab={(val) => setTab(val)} />
                </Col>
            </Row>

            {tab === 1 &&
                <Row className="page-teacherdetails-contents mt-3 m-0 ">
                    <div className="p-0">
                        <Row className="parent-student-list">
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">User Name</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Contact Number</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Email Address</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Date Added</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Status</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Actions</p>
                            </Col>
                        </Row>

                        {AllTeacherList?.data?.length > 0 && AllTeacherList?.data.map((item, index) =>
                            <Row className="parent-student-list">
                                <Col md={2} className="align-center text-start">
                                    <p className="m-0 fs-16 fw-500 fc-1E1E1E ff-InterMedium">{item?.name}</p>
                                </Col>
                                <Col md={2} className="align-center text-start">
                                    <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">{item?.phone}</p>
                                </Col>
                                <Col md={2} className="align-center text-start">
                                    <p className="m-0 fs-14 fw-400 fc-375D84 ff-InterRegular">{item?.email}</p>
                                </Col>
                                <Col md={2} className="align-center text-center">
                                    <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">{item?.date_joined}</p>
                                </Col>
                                <Col md={2} className="align-center text-center">
                                    <SwitchInput
                                        label={false}
                                        checked={item?.userstatus}
                                        onChange={(e) => handleSwitchChange(e, item)}
                                        color="secondary"
                                    />
                                </Col>
                                <Col md={2} className="align-center text-center">
                                    <MoreHorizIcon aria-describedby={id} style={{ fill: '#3C1053', cursor: "pointer" }} onClick={(e) => handlePopoverClick(e, item)} />
                                    <CustomPopover id={id} open={open} anchorEl={anchorEl} handleClose={handlePopoverClose} options={PopoverData} />
                                </Col>
                            </Row>
                        )}
                    </div>
                </Row>
            }

            {tab === 2 &&
                <Row className="page-teacherdetails-contents mt-3 m-0 ">
                    <div className="p-0">
                        <Row className="parent-student-list">
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">User Name</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Contact Number</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Email Address</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Date Added</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Status</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Actions</p>
                            </Col>
                        </Row>

                        {Array.from({ length: 10 }, (index) =>
                            <Row className="parent-student-list">
                                <Col md={2} className="align-center text-start">
                                    <p className="m-0 fs-16 fw-500 fc-1E1E1E ff-InterMedium">Ralph Edwards</p>
                                </Col>
                                <Col md={2} className="align-center text-start">
                                    <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">+974 9874563214</p>
                                </Col>
                                <Col md={2} className="align-center text-start">
                                    <p className="m-0 fs-14 fw-400 fc-375D84 ff-InterRegular">ralph123@gmail.com</p>
                                </Col>
                                <Col md={2} className="align-center text-center">
                                    <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">20/02/2023</p>
                                </Col>
                                <Col md={2} className="align-center text-center">
                                    <SwitchInput
                                        label={false}
                                        checked={isChecked}
                                        onChange={handleSwitchChange}
                                        color="secondary"
                                    />
                                </Col>
                                <Col md={2} className="align-center text-center">
                                    <MoreHorizIcon />
                                </Col>
                            </Row>
                        )}
                    </div>
                </Row>
            }

            {tab === 3 &&
                <Row className="page-teacherdetails-contents mt-3 m-0 ">
                    <div className="p-0">
                        <Row className="parent-student-list">
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">User Name</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Contact Number</p>
                            </Col>
                            <Col md={2} className="align-center text-start">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Email Address</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Date Added</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Status</p>
                            </Col>
                            <Col md={2} className="align-center text-center">
                                <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">Actions</p>
                            </Col>
                        </Row>

                        {Array.from({ length: 10 }, (index) =>
                            <Row className="parent-student-list">
                                <Col md={2} className="align-center text-start">
                                    <p className="m-0 fs-16 fw-500 fc-1E1E1E ff-InterMedium">Ralph Edwards</p>
                                </Col>
                                <Col md={2} className="align-center text-start">
                                    <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">+974 9874563214</p>
                                </Col>
                                <Col md={2} className="align-center text-start">
                                    <p className="m-0 fs-14 fw-400 fc-375D84 ff-InterRegular">ralph123@gmail.com</p>
                                </Col>
                                <Col md={2} className="align-center text-center">
                                    <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">20/02/2023</p>
                                </Col>
                                <Col md={2} className="align-center text-center">
                                    <SwitchInput
                                        label={false}
                                        checked={isChecked}
                                        onChange={handleSwitchChange}
                                        color="secondary"
                                    />
                                </Col>
                                <Col md={2} className="align-center text-center">
                                    <MoreHorizIcon />
                                </Col>
                            </Row>
                        )}
                    </div>
                </Row>
            }

            <Row className="mt-3 m-0 p-0">
                <Stack spacing={2} className="p-0">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        className="d-flex justify-end"
                    />
                </Stack>
            </Row>

            {/* Edit User modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isModalActive} handleClose={handleClose}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2"><FormattedMessage id="EditProfile" /></h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={handleClose} />
                                </div>
                            </Col>

                            <Col xs={12} md={12} className='mt-4'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.FullName" />}
                                    placeholder={intl.formatMessage({ id: "register.FullName" })}
                                    name="fullName"
                                    type="text"
                                    value={formValue.fullName}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.fullName.error && isError.fullName.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <SelectInput
                                    label={<FormattedMessage id="register.Country" />}
                                    placeholder={intl.formatMessage({ id: "register.Country" })}
                                    name={"country"}
                                    value={formValue?.country}
                                    data={countryList?.data}
                                    onChange={(e) => handleInputChange(e)}
                                    errorText={isError && isError.country.error && isError.country.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <SelectInput
                                    label={<FormattedMessage id="register.State" />}
                                    placeholder={intl.formatMessage({ id: "register.State" })}
                                    name={"state"}
                                    value={formValue?.state}
                                    data={stateList?.data}
                                    onChange={(e) => handleInputChange(e)}
                                    errorText={isError && isError.state.error && isError.state.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <SelectInput
                                    label={<FormattedMessage id="register.City" />}
                                    placeholder={intl.formatMessage({ id: "register.City" })}
                                    name={"city"}
                                    value={formValue?.city}
                                    data={cityList?.data}
                                    onChange={(e) => handleInputChange(e)}
                                    errorText={isError && isError.city.error && isError.city.errorMessage}
                                />
                            </Col>

                            <Col xs={12} md={6} className='mt-3'>
                                <LabelWithInput
                                    label={<FormattedMessage id="register.EmailId" />}
                                    placeholder={intl.formatMessage({ id: "register.EmailId" })}
                                    name="email"
                                    type="text"
                                    value={formValue?.email}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.email.error && isError.email.errorMessage}
                                    disabled
                                />
                            </Col>

                            <Col xs={12} md={12} className='mt-3'>
                                <div className="phone-number-container">
                                    <label className="phone-label">Phone Number</label>
                                    <div className="phone-input-wrapper">

                                        <div className="country-code-input">
                                            <PhoneInput
                                                country={'qa'}
                                                value={countryCode}
                                                onChange={handleCountryCodeChange}
                                                inputProps={{
                                                    name: 'countryCode',
                                                    required: true,
                                                }}
                                                placeholder="country"
                                                inputClass="custom-country-input"
                                                disableCountryCode={true}
                                                disableDropdown={false}
                                                onlyCountries={['qa', 'us', 'in', 'gb']}
                                            />
                                        </div>

                                        <div className="phone-number-input">
                                            <input
                                                type="number"
                                                name="phoneNumber"
                                                value={formValue?.phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                                ref={phoneNumberInputRef}
                                                placeholder="e.g: 8521364783"
                                                className={`custom-phone-input ${!isPhoneNumberValid ? 'invalid' : ''}`}
                                            />
                                        </div>
                                    </div>

                                    {isError && isError.phoneNumber.error && isError.phoneNumber.errorMessage ?
                                        <p className="error-message">{isError.phoneNumber.errorMessage}</p>
                                        :
                                        !isPhoneNumberValid ?
                                            <p className="error-message">Invalid phone number for selected country code</p>
                                            :
                                            ""
                                    }

                                </div>
                            </Col>

                        </Row>

                        <Row >
                            <Button variant="contained" onClick={() => handleSubmit()} className='button mt-5 md:mt-2'><FormattedMessage id="Save" /></Button>
                        </Row>
                    </Box>
                }
            />
            {/* Edit User modal  */}
        </Row>
    );
};

export default TeacherDetails;
