import { EmailValidation, PhoneNumberValidation } from "utils/helper";
import { FormattedMessage } from "react-intl";

const CheckValidation = value => {

    const formError = {
        submit: true,
        fullName: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        country: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        state: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        city: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        email: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        },
        phoneNumber: {
            error: false,
            errorMessage: <FormattedMessage id="fieldisrequired!" />
        }
    };

    // const emptyKeys = [
    //     'fullName',
    //     'country',
    //     'state',
    //     'city',
    //     'email',
    //     'phoneNumber'
    // ];

    const emptyKeys = [];
    for (let key in value) {
        emptyKeys.push(key);
    };


    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "email" && !EmailValidation(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
            formError[emptyKeys[i]].errorMessage = <FormattedMessage id="PleaseEnterValidEmail!" />
        }
    }
    return formError;
}

export default CheckValidation;