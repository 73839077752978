import { ACTIVE_INDEX } from "./constant";

export const basicDetailsCallApi = (type, data) => {
    switch (type) {
        case ACTIVE_INDEX:
            return {
                type: type,
                data: data
            }
    }
};