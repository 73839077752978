import { all, fork } from "redux-saga/effects";
import authLoginSaga from "./Auth/Login/saga";
import authLogoutSaga from "./Auth/Logout/saga";
import authResendSaga from "./Auth/Resend/saga";
import addTeacherSubjectSaga from "./Dashboard/AddTeacherSubject/saga";
import editBioSaga from "./Dashboard/Bio/saga";
import editProfileSaga from "./Dashboard/EditProfile/saga";
import editProfileImageSaga from "./Dashboard/EditProfileImage/saga";
import getProfileSaga from "./Dashboard/Profile/saga";
import addQualificationSaga from "./Dashboard/Qualification/saga";
import authRegisterSaga from "./Auth/Registration/Auth/saga";
import cityDropDownSaga from "./Auth/Registration/City/saga";
import countryDropDownSaga from "./Auth/Registration/Country/saga";
import gradeLevelDropDownSaga from "./Auth/Registration/GreadeLevel/saga";
import languageDropDownSaga from "./Auth/Registration/Language/saga";
import qualificationDropDownSaga from "./Auth/Registration/Qualification/saga";
import stateDropDownSaga from "./Auth/Registration/State/saga";
import subjectDropDownSaga from "./Auth/Registration/Subject/saga";
import teachingMaterialDropDownSaga from "./Auth/Registration/TeachingMaterial/saga";
import authEmailVerificationSaga from "./Auth/Forgot/EmailVerfication/saga";
import authOTPVerificationSaga from "./Auth/Forgot/OtpVerification/saga";
import authResetPasswordSaga from "./Auth/Forgot/ResetPassword/saga";
import editQualificationSaga from "./Dashboard/EditQualification/saga";
import assessmentVerificationSaga from "./Dashboard/AssessmentVerifiedEmail/saga";
import getQuestionsSaga from "./Dashboard/GetQuestions/saga";
import submitQuestionsSaga from "./Dashboard/SubmitQuestions/saga";
import getTeacherAvailabilitySaga from "./Dashboard/GetTeacherAvailability/saga";
import addTeacherAvailabilitySaga from "./Dashboard/AddTeacherAvailability/saga";
import repeatFrequencyDropDownSaga from "./Dashboard/Availability/RepeatFrequency/saga";
import weekDaysDropDownSaga from "./Dashboard/Availability/WeekDays/saga";
import getAvailableTeacherListSaga from "./Dashboard/GetAvailableTeacherList/saga";
import bookInterViewSlotSaga from "./Dashboard/BookInterViewSlot/saga";
import getInterviewFeedbackSaga from "./Dashboard/InterViewFeedback/saga";
import editTeacherAvailabilitySaga from "./Dashboard/EditTeacherAvailability/saga";
import deleteTeacherAvailabilitySaga from "./Dashboard/DeleteTeacherAvailability/saga";
import getAllStudentsListSaga from "./AdminDashboard/GetAllStudentLists/saga";
import getAllTeachersListSaga from "./AdminDashboard/GetAllTeacherLists/saga";
import updateUserStatusSaga from "./AdminDashboard/UpdateUserStatus/saga";
import getAllInternalTeachersListSaga from "./AdminDashboard/GetAllInternalTeacherLists/saga";
import updateUserDataSaga from "./AdminDashboard/UpdateUserData/saga";

const rootSaga = function* () {
   yield all([
      fork(countryDropDownSaga),
      fork(stateDropDownSaga),
      fork(cityDropDownSaga),
      fork(qualificationDropDownSaga),
      fork(teachingMaterialDropDownSaga),
      fork(gradeLevelDropDownSaga),
      fork(languageDropDownSaga),
      fork(subjectDropDownSaga),
      fork(authRegisterSaga),
      fork(authLoginSaga),
      fork(authLogoutSaga),
      fork(getProfileSaga),
      fork(editBioSaga),
      fork(addQualificationSaga),
      fork(editProfileSaga),
      fork(addTeacherSubjectSaga),
      fork(editProfileImageSaga),
      fork(authResendSaga),
      fork(authEmailVerificationSaga),
      fork(authOTPVerificationSaga),
      fork(authResetPasswordSaga),
      fork(editQualificationSaga),
      fork(assessmentVerificationSaga),
      fork(getQuestionsSaga),
      fork(submitQuestionsSaga),
      fork(getTeacherAvailabilitySaga),
      fork(addTeacherAvailabilitySaga),
      fork(repeatFrequencyDropDownSaga),
      fork(weekDaysDropDownSaga),
      fork(getAvailableTeacherListSaga),
      fork(bookInterViewSlotSaga),
      fork(getInterviewFeedbackSaga),
      fork(editTeacherAvailabilitySaga),
      fork(deleteTeacherAvailabilitySaga),
      fork(getAllStudentsListSaga),
      fork(getAllTeachersListSaga),
      fork(updateUserStatusSaga),
      fork(getAllInternalTeachersListSaga),
      fork(updateUserDataSaga),
   ]);
};

export default rootSaga;